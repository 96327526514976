/** Redux */
import * as types from "../actions/types";

/** Types */
import type { Middleware } from "redux";

const networkMiddleware: Middleware = (store) => {
  const updateOnlineStatus = () => {
    store.dispatch({
      on_line: navigator.onLine,
      type: types.UPDATE_NETWORK_STATUS,
    });
  };

  const startNetworkMonitoring = () => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
  };

  const stopNetworkMonitoring = () => {
    window.removeEventListener("online", updateOnlineStatus);
    window.removeEventListener("offline", updateOnlineStatus);
  };

  return (next: any) => (action: any) => {
    switch (action.type) {
      case types.START_NETWORK_MONITORING:
        startNetworkMonitoring();
        break;
      case types.STOP_NETWORK_MONITORING:
        stopNetworkMonitoring();
        break;
    }
    next(action);
  };
};

export default networkMiddleware;
