/** Vendors */
import { useSearchParams } from "react-router-dom";
import { Button, Flex, Typography } from "antd";

/** Types */
interface IAuthErrorScreen {
  actions: { onLogout: () => void };
}

function AuthErrorScreen({ actions }: IAuthErrorScreen) {
  const [search] = useSearchParams();
  const error = search.get("error");

  return (
    <Flex align="center" className="h-100 w-100" justify="center">
      <Flex align="center" gap={12} justify="center" vertical>
        <Typography.Title level={2}>
          Seems an error was encountered
        </Typography.Title>
        <Typography.Text>
          Please reset your session or contact us at&nbsp;
          <a href="mailto:support@imstools.us">support@imstools.us</a> if the
          issue persists.
        </Typography.Text>
        <Typography.Text type="danger">{error}</Typography.Text>
        <Button onClick={actions.onLogout} type="default">
          Reset Session
        </Button>
      </Flex>
    </Flex>
  );
}

export default AuthErrorScreen;
