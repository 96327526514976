/** Redux */
import * as types from "./types";

/** Custom APIs */
import {
  subscribeToNotifications,
  unsubscribeToNotifications,
} from "../../api/notifications";

/** Types */
import type { IAppDispatch } from "../configureStore";

import type {
  IAnyObject,
  INotification,
  INotificationTemplate,
  ISearchNotificationTemplates,
  ISearchNotifications,
} from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function subscribeToWebPushAction() {
  return (dispatch: IAppDispatch) => {
    subscribeToNotifications()
      .then((subscription) => {
        sendMessage(dispatch)({
          action: "notification",
          method: "PUT",
          path: "notification/subscription",
          member: {
            subscriptions: [JSON.parse(JSON.stringify(subscription))],
          },
          type: types.ADD_PUSH_SUBSCRIPTION_SUCCESS,
        });
      })
      .catch(console.log);
  };
}

export function unsubscribeFromWebPushAction() {
  return (dispatch: IAppDispatch) => {
    unsubscribeToNotifications().then((subscription) => {
      sendMessage(dispatch)({
        action: "notification",
        method: "DELETE",
        path: "notification/subscription",
        subscription,
        type: types.REMOVE_PUSH_SUBSCRIPTION_SUCCESS,
      });
    });
  };
}

/** Notification Actions */
export function createNotificationAction(notification: INotification) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "PUT",
      notification,
      path: "notification",
      type: types.CREATE_NOTIFICATION_SUCCESS,
    });
  };
}

export function deleteNotificationAction(notification: INotification) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "DELETE",
      notification,
      path: "notification",
      type: types.DELETE_NOTIFICATION_SUCCESS,
    });
  };
}

export function resetNotificationsAction() {
  return (dispatch: IAppDispatch) => {
    dispatch({ type: types.RESET_NOTIFICATIONS_SUCCESS });
  };
}

export function searchNotificationsAction(search: ISearchNotifications) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "GET",
      path: "notification/search",
      search,
      type: types.SEARCH_NOTIFICATIONS_SUCCESS,
    });
  };
}

export function updateNotificationAction(notification: INotification) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "POST",
      notification,
      path: "notification",
      type: types.UPDATE_NOTIFICATION_SUCCESS,
    });
  };
}

/* Notification Template(s) */
export function createNotificationTemplateAction(
  template: INotificationTemplate
) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "PUT",
      path: "notification/template",
      template,
      type: types.CREATE_NOTIFICATION_TEMPLATE_SUCCESS,
    });
  };
}

export function deleteNotificationTemplateAction(
  template: INotificationTemplate
) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "DELETE",
      path: "notification/template",
      template,
      type: types.DELETE_NOTIFICATION_TEMPLATE_SUCCESS,
    });
  };
}

export function getNotificationTemplateAction(template: INotificationTemplate) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "GET",
      path: "notification/template",
      template,
      type: types.GET_NOTIFICATION_TEMPLATE_SUCCESS,
    });
  };
}

export function searchNotificationTemplatesAction(
  search: ISearchNotificationTemplates
) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "GET",
      path: "notification/template/search",
      search,
      type: types.SEARCH_NOTIFICATION_TEMPLATES_SUCCESS,
    });
  };
}

export function setActiveNotificationTemplateAction(
  template: INotificationTemplate
) {
  return (dispatch: IAppDispatch) =>
    dispatch({
      template,
      type: types.SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS,
    });
}

export function updateNotificationTemplateAction(
  template: INotificationTemplate
) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "notification",
      method: "POST",
      path: "notification/template",
      template,
      type: types.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS,
    });
  };
}
