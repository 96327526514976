import { Suspense, lazy } from "react";

/** Vendors */
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Col, Row, Space } from "antd";

/** Custom CSS */
import "../recording.css";

/** Types */
import type { IView } from "types";

/** Custom Components */
import Examples from "../cards/Examples";
import LoadingScreen from "../../shared/loading/Screen";
import Messenger from "../../chat/cards/chat/Messenger";
import TranscriptionList from "../cards/List";
const Navigation = lazy(() => import("../shared/Navigation"));

function Overview({ helmet }: IView) {
  const location = useLocation();

  return (
    <div className="recording">
      <Helmet>
        <title>{helmet.title}</title>
        <link
          rel="canonical"
          href={`${import.meta.env.VITE_REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <Suspense fallback={<LoadingScreen />}>
        <Space className="w-100" direction="vertical">
          <Navigation view="recording" />
          <div className="overview">
            <Row gutter={[12, 12]}>
              <Col lg={10} md={12} sm={24} xs={24}>
                <Space className="w-100" direction="vertical" size="large">
                  <Examples />
                  <TranscriptionList />
                </Space>
              </Col>
              <Col lg={14} md={12} sm={24} xs={24}>
                <Messenger />
              </Col>
            </Row>
          </div>
        </Space>
      </Suspense>
    </div>
  );
}

export default Overview;
