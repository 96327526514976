/** Vendors */
import { Button, Popconfirm, Popover } from "antd";

/** Types */
import type { IChannel, IListRowOptions } from "types";

function Options({ actions, children, details }: IListRowOptions<IChannel>) {
  return (
    <Popover
      content={
        <div className="flex-column" style={{ maxWidth: 160 }}>
          <Button block onClick={actions.toggle(details)} type="text">
            Edit Channel
          </Button>
          <Button block danger type="text">
            <Popconfirm
              title="Are you sure delete this channel?"
              onConfirm={actions.delete(details)}
              okText="Yes"
              cancelText="No"
            >
              Delete Channel
            </Popconfirm>
          </Button>
        </div>
      }
      placement="left"
    >
      {children}
    </Popover>
  );
}

export default Options;
