/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";
import type { IAnyObject, IBroadcast, ISearchBroadcasts } from "types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function createBroadcastAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      broadcast,
      method: "PUT",
      path: "broadcast",
      type: types.CREATE_BROADCAST_SUCCESS,
    });
  };
}

export function deleteBroadcastAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      broadcast,
      method: "DELETE",
      path: "broadcast",
      type: types.DELETE_BROADCAST_SUCCESS,
    });
  };
}

export function getBroadcastAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      broadcast,
      method: "GET",
      path: "broadcast",
      type: types.GET_BROADCAST_SUCCESS,
    });
  };
}

export function getBroadcastMetricsAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      broadcast,
      method: "GET",
      path: "broadcast/metrics",
      type: types.GET_BROADCAST_METRICS_SUCCESS,
    });
  };
}

export function searchBroadcastsAction(search: ISearchBroadcasts) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      method: "GET",
      path: "broadcast/search",
      search,
      type: types.SEARCH_BROADCASTS_SUCCESS,
    });
  };
}

export function sendBroadcastMessageAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      broadcast,
      method: "POST",
      path: "broadcast/initiate",
      type: types.SEND_BROADCAST_SUCCESS,
    });
  };
}

export function setActiveBroadcastAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) =>
    dispatch({
      broadcast,
      type: types.SET_ACTIVE_BROADCAST_SUCCESS,
    });
}

export function updateBroadcastAction(broadcast: IBroadcast) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "broadcast",
      broadcast,
      method: "POST",
      path: "broadcast",
      type: types.UPDATE_BROADCAST_SUCCESS,
    });
  };
}
