/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

const access = createReducer(initialState.access, (builder) => {
  builder
    .addCase(
      createAction(types.GET_ACCESS_SUCCESS),
      (state: IRootState["access"], action: AnyAction) => ({
        ...state,
        [`${action.access_key}.${
          action.resource_name ? action.resource_name : ""
        }`]: action.enabled,
      })
    )
    .addDefaultCase((state) => state);
});

export default access;
