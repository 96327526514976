/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";
import type { INotification, IStandardList } from "../../types";

const notificationAssignment =
  (method: string) =>
  (state: IRootState["notification"], action: AnyAction) => {
    const details = action.notification;
    const channel = action.notification?.parent;

    if (method === "CREATE") {
      if (channel in state) {
        return {
          ...state,
          [channel]: redux.addResources<INotification>(
            state[channel],
            action,
            ResourceId.Notification
          ),
        };
      } else {
        return {
          ...state,
          [channel]: { dataSetKey: "", details, list: [details] },
        };
      }
    } else if (method === "DELETE") {
      if (channel in state) {
        return {
          ...state,
          [channel]: redux.removeMatchCase<INotification>(
            state[channel],
            action,
            ResourceId.Notification
          ),
        };
      } else {
        return state;
      }
    } else if (method === "GET") {
      if (channel in state) {
        return { ...state, [channel]: { ...state[channel], details } };
      } else {
        return {
          ...state,
          [channel]: { dataSetKey: "", details, list: [details] },
        };
      }
    } else if (method === "UPDATE") {
      if (channel in state) {
        return {
          ...state,
          [channel]: redux.updateMatchCase<INotification>(
            state[channel],
            action,
            ResourceId.Notification
          ),
        };
      } else {
        return {
          ...state,
          [channel]: { dataSetKey: "", details, list: [details] },
        };
      }
    } else if (method === "SEARCH") {
      const reorganized = action.results.reduce(
        (
          acc: { [channel: string]: IStandardList<INotification> },
          notification: INotification
        ) => {
          const channel = notification.parent;
          if (channel) {
            if (!(channel in acc)) {
              acc[channel] = {
                dataSetKey: action.dataSetKey,
                details: {},
                list: [],
              };
            }
            acc[channel] = {
              ...acc[channel],
              list: [...acc[channel].list, notification],
            };
          }

          return acc;
        },
        {}
      );

      for (const channel in reorganized) {
        if (channel in state) {
          return {
            ...state,
            [channel]: redux.joinOrOverwrite<INotification>(
              state[channel],
              {
                ...reorganized[channel],
                results: reorganized[channel].list || [],
              },
              "list"
            ),
          };
        } else {
          return { ...state, [channel]: reorganized[channel] };
        }
      }
    }
  };

const notification = createReducer(initialState.notification, (builder) => {
  /** Notification Reducer */
  builder
    .addCase(
      createAction(types.CREATE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => {
        return notificationAssignment("CREATE")(state, action);
      }
    )
    .addCase(
      createAction(types.DELETE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => {
        return notificationAssignment("DELETE")(state, action);
      }
    )
    .addCase(
      createAction(types.GET_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => {
        return notificationAssignment("GET")(state, action);
      }
    )
    .addCase(createAction(types.RESET_NOTIFICATIONS_SUCCESS), () => ({}))
    .addCase(
      createAction(types.SEARCH_NOTIFICATIONS_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => {
        return notificationAssignment("SEARCH")(state, action);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => {
        return notificationAssignment("GET")(state, action);
      }
    )
    .addCase(
      createAction(types.UPDATE_NOTIFICATION_SUCCESS),
      (state: IRootState["notification"], action: AnyAction) => {
        return notificationAssignment("UPDATE")(state, action);
      }
    )
    .addDefaultCase((state) => state);
});

export default notification;
