/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { IMember } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const contact = createReducer(initialState.contact, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_CONTACT_SUCCESS),
      (state: IRootState["contact"], action: AnyAction) => {
        return redux.addResources<IMember>(state, action, ResourceId.Member);
      }
    )
    .addCase(
      createAction(types.DELETE_CONTACT_SUCCESS),
      (state: IRootState["contact"], action: AnyAction) => {
        return redux.removeMatchCase<IMember>(state, action, ResourceId.Member);
      }
    )
    .addCase(
      createAction(types.GET_CONTACT_SUCCESS),
      (state: IRootState["contact"], action: AnyAction) => {
        return { ...state, details: action.member };
      }
    )
    .addCase(
      createAction(types.SEARCH_CONTACTS_SUCCESS),
      (state: IRootState["contact"], action: AnyAction) => {
        return redux.joinOrOverwrite<IMember>(state, action);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_CONTACT_SUCCESS),
      (state: IRootState["contact"], action: AnyAction) => {
        return { ...state, details: action.member };
      }
    )
    .addCase(
      createAction(types.TOGGLE_CONTACT_SIDEBAR),
      (state: IRootState["contact"], action: AnyAction) => {
        return { ...state, details: action.details };
      }
    )
    .addCase(
      createAction(types.TOGGLE_CONTACT_MODAL),
      (state: IRootState["contact"], action: AnyAction) => {
        return { ...state, details: action.details };
      }
    )
    .addCase(
      createAction(types.UPDATE_CONTACT_SUCCESS),
      (state: IRootState["contact"], action: AnyAction) => {
        return redux.updateMatchCase<IMember>(state, action, ResourceId.Member);
      }
    )
    .addDefaultCase((state) => state);
});

export default contact;
