/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";
import type { ICheckin, ICheckinStep } from "../../types";

const checkin = createReducer(initialState.checkin, (builder) => {
  /** Checkin Details and List */
  builder
    .addCase(
      createAction(types.CREATE_CHECKIN_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => {
        return redux.addResources<ICheckin>(state, action, "checkin");
      }
    )
    .addCase(
      createAction(types.DELETE_CHECKIN_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) =>
        redux.removeMatchCase<ICheckin>(state, action, "checkin")
    )
    .addCase(
      createAction(types.GET_CHECKIN_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        details: action.checkin || {},
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_CHECKIN_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        details: action.checkin || {},
      })
    )
    .addCase(
      createAction(types.TOGGLE_CHECKIN_MODAL),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        details: action.details || {},
      })
    )
    .addCase(
      createAction(types.UPDATE_CHECKIN_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => {
        return redux.updateMatchCase<ICheckin>(state, action, "checkin");
      }
    )
    .addCase(
      createAction(types.SEARCH_CHECKINS_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => {
        return redux.joinOrOverwrite<ICheckin>(state, action);
      }
    );

  /** Checkin Step Details and Step List */
  builder
    .addCase(
      createAction(types.CREATE_CHECKIN_STEP_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: redux.addResources<ICheckin>(state.step, action, "step"),
      })
    )
    .addCase(
      createAction(types.DELETE_CHECKIN_STEP_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: redux.removeMatchCase<ICheckin>(state.step, action, "step"),
      })
    )
    .addCase(
      createAction(types.GET_CHECKIN_STEP_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: { ...state.step, details: action.step },
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_CHECKIN_STEP_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: { ...state.step, details: action.step },
      })
    )
    .addCase(
      createAction(types.TOGGLE_CHECKIN_STEP_MODAL),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: { ...state.step, details: action.step },
      })
    )
    .addCase(
      createAction(types.UPDATE_CHECKIN_STEP_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: redux.updateMatchCase<ICheckin>(state.step, action, "step"),
      })
    )
    .addCase(
      createAction(types.SEARCH_CHECKIN_STEPS_SUCCESS),
      (state: IRootState["checkin"], action: AnyAction) => ({
        ...state,
        step: redux.joinOrOverwrite<ICheckinStep>(state.step, action),
      })
    )
    .addDefaultCase((state) => state);
});

export default checkin;
