import { Suspense, lazy } from "react";

/** Vendors */
import { Helmet } from "react-helmet";
import { Space } from "antd";
import { useLocation } from "react-router-dom";

/** Custom CSS */
import "../broadcast.css";

/** Custom Components */
import LoadingScreen from "../../shared/loading/Screen";
const ChannelMembers = lazy(() => import("../cards/channels/Participants"));
const ChannelList = lazy(() => import("../cards/channels/List"));
const Navigation = lazy(() => import("../shared/Navigation"));

/** Types */
import type { IView } from "types";

function Channels({ helmet }: IView) {
  const location = useLocation();

  return (
    <div className="broadcast">
      <Helmet>
        <title>{helmet.title}</title>
        <link
          rel="canonical"
          href={`${import.meta.env.VITE_REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <Suspense fallback={<LoadingScreen />}>
        <Space className="w-100" direction="vertical">
          <Navigation view="channels" />
          <div className="channels">
            <ChannelList />
            <ChannelMembers />
          </div>
        </Space>
      </Suspense>
    </div>
  );
}

export default Channels;
