/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { IPreference } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const preference = createReducer(initialState.preference, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_PREFERENCE_SUCCESS),
      (state: IRootState["preference"], action: AnyAction) => {
        return redux.addResources<IPreference>(
          state,
          action,
          ResourceId.Preference
        );
      }
    )
    .addCase(
      createAction(types.DELETE_PREFERENCE_SUCCESS),
      (state: IRootState["preference"], action: AnyAction) => {
        return redux.removeMatchCase<IPreference>(state, action, "preference");
      }
    )
    .addCase(
      createAction(types.GET_PREFERENCE_SUCCESS),
      (state: IRootState["preference"], action: AnyAction) => {
        return { ...state, details: action.preference };
      }
    )
    .addCase(
      createAction(types.SEARCH_PREFERENCES_SUCCESS),
      (state: IRootState["preference"], action: AnyAction) => {
        return redux.joinOrOverwrite<IPreference>(state, action);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_PREFERENCE_SUCCESS),
      (state: IRootState["preference"], action: AnyAction) => {
        return { ...state, details: action.preference };
      }
    )
    .addCase(
      createAction(types.UPDATE_PREFERENCE_SUCCESS),
      (state: IRootState["preference"], action: AnyAction) => {
        return redux.updateMatchCase<IPreference>(state, action, "preference");
      }
    )
    .addDefaultCase((state) => state);
});

export default preference;
