/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

const toggler = redux.toggleState;

const toggle = createReducer(initialState.toggle, (builder) => {
  builder
    .addCase(createAction(types.TOGGLE_AAR_MODAL), (state, action) => {
      return toggler(state, action, "aarModal");
    })
    .addCase(createAction(types.TOGGLE_BROADCAST_MODAL), (state, action) => {
      return toggler(state, action, "broadcastModal");
    })
    .addCase(createAction(types.TOGGLE_CHANNEL_MODAL), (state, action) => {
      return toggler(state, action, "channelModal");
    })
    .addCase(createAction(types.TOGGLE_CHANNEL_SIDEBAR), (state, action) => {
      return toggler(state, action, "channelSidebar");
    })
    .addCase(createAction(types.TOGGLE_CHECKIN_MODAL), (state, action) => {
      return toggler(state, action, "checkinModal");
    })
    .addCase(createAction(types.TOGGLE_CHECKIN_STEP_MODAL), (state, action) => {
      return toggler(state, action, "checkinStepModal");
    })
    .addCase(createAction(types.TOGGLE_CONTACT_MODAL), (state, action) => {
      return toggler(state, action, "contactModal");
    })
    .addCase(createAction(types.TOGGLE_CONTACT_SIDEBAR), (state, action) => {
      return toggler(state, action, "contactSidebar");
    })

    .addCase(createAction(types.TOGGLE_DUTY_MODAL), (state, action) => {
      return toggler(state, action, "dutyModal");
    })
    .addCase(
      createAction(types.TOGGLE_BARRACKS_MAINTENANCE_MODAL),
      (state, action) => {
        return toggler(state, action, "barracksMaintenanceModal");
      }
    )
    .addCase(createAction(types.TOGGLE_FILTER_MODAL), (state, action) => {
      return toggler(state, action, "filterModal");
    })
    .addCase(createAction(types.TOGGLE_INVITATION_MODAL), (state, action) => {
      return toggler(state, action, "inviteMembersModal");
    })
    .addCase(createAction(types.TOGGLE_LICENSE_MODAL), (state, action) => {
      return toggler(state, action, "licenseModal");
    })
    .addCase(
      createAction(types.TOGGLE_LICENSE_REQUEST_MODAL),
      (state, action) => {
        return toggler(state, action, "licenseRequestModal");
      }
    )
    .addCase(createAction(types.TOGGLE_LEFT_SIDEBAR), (state, action) => {
      return toggler(state, action, "leftSidebar");
    })
    .addCase(createAction(types.TOGGLE_METADATA_MODAL), (state, action) => {
      return toggler(state, action, "metadataModal");
    })
    .addCase(createAction(types.TOGGLE_NOTIFICATION), (state, action) => {
      return toggler(state, action, "notification");
    })
    .addCase(
      createAction(types.TOGGLE_NOTIFICATION_TEMPLATE_MODAL),
      (state, action) => {
        return toggler(state, action, "notificationTemplateModal");
      }
    )
    .addCase(createAction(types.TOGGLE_RFD_GROUP_MODAL), (state, action) => {
      return toggler(state, action, "rfdGroupModal");
    })
    .addCase(createAction(types.TOGGLE_RFD_RECALL_MODAL), (state, action) => {
      return toggler(state, action, "rfdRecallModal");
    })
    .addCase(
      createAction(types.TOGGLE_RFD_RECALL_RESPONSE_MODAL),
      (state, action) => {
        return toggler(state, action, "rfdRecallResponseModal");
      }
    )
    .addCase(
      createAction(types.TOGGLE_RFD_RECALL_ROLLUP_MODAL),
      (state, action) => {
        return toggler(state, action, "rfdRecallRollupModal");
      }
    )
    .addCase(
      createAction(types.TOGGLE_RFD_REQUIREMENTS_MODAL),
      (state, action) => {
        return toggler(state, action, "rfdRequirementModal");
      }
    )
    .addCase(
      createAction(types.TOGGLE_RFD_REQUIREMENT_VERIFICATION_MODAL),
      (state, action) => {
        return toggler(state, action, "rfdRequirementVerificationModal");
      }
    )
    .addCase(createAction(types.TOGGLE_RFD_ROSTER_MODAL), (state, action) => {
      return toggler(state, action, "rfdRosterModal");
    })
    .addCase(createAction(types.TOGGLE_SURVEY_MODAL), (state, action) => {
      return toggler(state, action, "surveyModal");
    })
    .addCase(createAction(types.TOGGLE_TICKET_MODAL), (state, action) => {
      return toggler(state, action, "ticketModal");
    })
    .addCase(
      createAction(types.TOGGLE_TICKET_CATEGORY_MODAL),
      (state, action) => {
        return toggler(state, action, "ticketCategoryModal");
      }
    )
    .addCase(createAction(types.TOGGLE_TRANSCRIBE_MODAL), (state, action) => {
      return toggler(state, action, "transcribeModal");
    })
    .addDefaultCase((state) => state);
});

export default toggle;
