/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";

import type {
  INotification,
  ITicket,
  ITicketCategory,
  ITicketSubcategory,
} from "types";

/** Ticket Actions */
const ticket = createReducer(initialState.ticket, (builder) => {
  /** Ticket Category */
  builder
    .addCase(
      createAction(types.CREATE_TICKET_CATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: redux.addResources<ITicketCategory>(
          state.category,
          action,
          "category"
        ),
      })
    )
    .addCase(
      createAction(types.DELETE_TICKET_CATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: redux.removeMatchCase<ITicketCategory>(
          state.category,
          action,
          "category"
        ),
      })
    )
    .addCase(
      createAction(types.GET_TICKET_CATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: redux.updateMatchCase<ITicketCategory>(
          state.category,
          action,
          "category"
        ),
      })
    )
    .addCase(
      createAction(types.SEARCH_TICKET_CATEGORIES_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: redux.joinOrOverwrite<ITicketCategory>(
          state.category,
          action
        ),
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_TICKET_CATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: { ...state.category, details: action.category },
      })
    )
    .addCase(
      createAction(types.TOGGLE_TICKET_CATEGORY_MODAL),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: { ...state.category, details: action.category },
      })
    )
    .addCase(
      createAction(types.UPDATE_TICKET_CATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        category: redux.updateMatchCase<ITicketCategory>(
          state.category,
          action,
          "category"
        ),
      })
    );

  /** Ticket Details */
  builder
    .addCase(
      createAction(types.CREATE_TICKET_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => {
        return redux.addResources<ITicket>(state, action, "ticket");
      }
    )
    .addCase(
      createAction(types.DELETE_TICKET_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => {
        return redux.removeMatchCase<ITicket>(state, action, "ticket");
      }
    )
    .addCase(
      createAction(types.GET_TICKET_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => {
        return { ...state, details: action.ticket };
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_TICKET_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => {
        return redux.joinOrOverwrite<ITicket>(state, action);
      }
    )
    .addCase(
      createAction(types.TOGGLE_LICENSE_REQUEST_MODAL),
      (state: IRootState["ticket"], action: AnyAction) => {
        return { ...state, details: action.ticket };
      }
    )
    .addCase(
      createAction(types.TOGGLE_TICKET_MODAL),
      (state: IRootState["ticket"], action: AnyAction) => {
        return { ...state, details: action.ticket };
      }
    )
    .addCase(
      createAction(types.UPDATE_TICKET_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => {
        return redux.updateMatchCase<ITicket>(state, action, "ticket");
      }
    );

  /** Ticket History */
  builder
    .addCase(
      createAction(types.CREATE_TICKET_HISTORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        history: redux.addResources<INotification>(
          state.history,
          action,
          "notification"
        ),
      })
    )
    .addCase(
      createAction(types.SEARCH_TICKET_HISTORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        history: redux.joinOrOverwrite<INotification>(state.history, action),
      })
    )
    .addCase(
      createAction(types.RESET_TICKET_HISTORY_SUCCESS),
      (state: IRootState["ticket"]) => ({
        ...state,
        history: { ...state.history, list: [] },
      })
    );

  /** Ticket Metrics */
  builder.addCase(
    createAction(types.GET_TICKET_METRICS),
    (state: IRootState["ticket"], action: AnyAction) => ({
      ...state,
      metrics: action.results[0],
    })
  );

  /** Ticket Sub Categories */
  builder
    .addCase(
      createAction(types.CREATE_TICKET_SUBCATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => {
        return {
          ...state,
          subcategory: redux.addResources<ITicketSubcategory>(
            state.subcategory,
            action,
            "subcategory"
          ),
        };
      }
    )
    .addCase(
      createAction(types.DELETE_TICKET_SUBCATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        subcategory: redux.removeMatchCase<ITicketSubcategory>(
          state.subcategory,
          action,
          "subcategory"
        ),
      })
    )
    .addCase(
      createAction(types.GET_TICKET_SUBCATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        subcategory: { ...state.subcategory, details: action.subcategory },
      })
    )
    .addCase(
      createAction(types.SEARCH_TICKET_SUBCATEGORIES_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        subcategory: redux.joinOrOverwrite<ITicketSubcategory>(
          state.subcategory,
          action
        ),
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_TICKET_SUBCATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        subcategory: { ...state.subcategory, details: action.subcategory },
      })
    )
    .addCase(
      createAction(types.TOGGLE_TICKET_SUBCATEGORY_MODAL),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        subcategory: { ...state.subcategory, details: action.subcategory },
      })
    )
    .addCase(
      createAction(types.UPDATE_TICKET_SUBCATEGORY_SUCCESS),
      (state: IRootState["ticket"], action: AnyAction) => ({
        ...state,
        subcategory: redux.updateMatchCase<ITicketSubcategory>(
          state.subcategory,
          action,
          "subcategory"
        ),
      })
    )
    .addDefaultCase((state) => state);
});

export default ticket;
