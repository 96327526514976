/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";

/** Enums */
import { Tool } from "../../types";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";
import type { IAnyObject, IMember, IOrganization } from "../../types";

const search = createReducer(initialState.search, (builder) => {
  /** Group Reducer */
  builder
    .addCase(
      createAction(types.DELETE_MEMBER_SUCCESS),
      (state: IRootState["search"]) => ({
        ...state,
        text: "",
      })
    )
    .addCase(
      createAction(types.RESET_SEARCH_SUCCESS),
      (state: IRootState["search"]) => ({
        ...state,
        active: Tool.Contacts,
        details: {} as IAnyObject,
        hierarchy: {
          children: [] as IOrganization[],
          member: {} as IMember,
          organization: {} as IOrganization,
          parent: {} as IOrganization,
        },
        is_top_level: false,
        list: [],
        placeholder: "Search for contacts...",
        text: "",
      })
    )
    .addCase(
      createAction(types.UPDATE_SEARCH_SUCCESS),
      (state: IRootState["search"], action: AnyAction) => ({
        ...state,
        ...action.search,
      })
    )
    .addCase(
      createAction(types.SEARCH_HIERARCHY_SUCCESS),
      (state: IRootState["search"], action: AnyAction) => ({
        ...state,
        hierarchy: action.hierarchy,
      })
    )
    .addDefaultCase((state) => state);
});

export default search;
