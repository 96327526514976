/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { INotificationTemplate } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const template = createReducer(initialState.template, (builder) => {
  /** Notification Template Reducer */
  builder
    .addCase(
      createAction(types.CREATE_NOTIFICATION_TEMPLATE_SUCCESS),
      (state: IRootState["template"], action: AnyAction) => (
        redux.addResources<INotificationTemplate>(
          state,
          action,
          ResourceId.NotificationTemplate
        )
      )
    )
    .addCase(
      createAction(types.DELETE_NOTIFICATION_TEMPLATE_SUCCESS),
      (state: IRootState["template"], action: AnyAction) => (
        redux.removeMatchCase<INotificationTemplate>(
          state,
          action,
          ResourceId.NotificationTemplate
        )
      )
    )
    .addCase(
      createAction(types.GET_NOTIFICATION_TEMPLATE_SUCCESS),
      (state: IRootState["template"], action: AnyAction) => (
        redux.addResources<INotificationTemplate>(
          state,
          action,
          ResourceId.NotificationTemplate
        )
      )
    )
    .addCase(
      createAction(types.SEARCH_NOTIFICATION_TEMPLATES_SUCCESS),
      (state: IRootState["template"], action: AnyAction) => (
        redux.joinOrOverwrite<INotificationTemplate>(
          state,
          action
        )
      )
    )
    .addCase(
      createAction(types.SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS),
      (state: IRootState["template"], action: AnyAction) => ({
        ...state.template, details: action.template
      })
    )
    .addCase(
      createAction(types.TOGGLE_NOTIFICATION_TEMPLATE_MODAL),
      (state: IRootState["template"], action: AnyAction) => ({
        ...state.template, details: action.details
      })
    )
    .addCase(
      createAction(types.UPDATE_NOTIFICATION_TEMPLATE_SUCCESS),
      (state: IRootState["template"], action: AnyAction) => (
        redux.updateMatchCase<INotificationTemplate>(
          state,
          action,
          ResourceId.NotificationTemplate
        )
      )
    )
    .addDefaultCase((state) => state);
});

export default template;
