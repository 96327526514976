/** Vendors */
import { configureStore } from "@reduxjs/toolkit";

/** Custom Reducers */
import initialState from "./reducers/initialState";
import rootReducer from "./reducers/index";

/** Custom APIs */
import { member } from "../api/member";
import metadata from "../api/metadata";

/** Middleware */
import network from "./middleware/network";
import notification from "./middleware/notification";
import socket from "./middleware/socket";

/** Types */
import type { ThunkAction } from "redux-thunk";
import type {
  Action,
  AnyAction,
  DevToolsEnhancerOptions,
  Middleware,
} from "@reduxjs/toolkit";

declare module "redux" {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const middleware: Middleware[] = [network, notification, socket];

const devToolsEnhancer: DevToolsEnhancerOptions = {
  trace: true,
  traceLimit: 25,
};

const extraArgument = {
  member,
  metadata,
};

const store = configureStore({
  devTools: import.meta.env.DEV ? devToolsEnhancer : false,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
    }).concat(middleware);
  },
  preloadedState: initialState,
  reducer: rootReducer,
});

// Infer the `IRootState` and `IAppDispatch` types from the store itself
export type IAppApi = typeof extraArgument;

export type IGetState = typeof store.getState;

export type IRootState = ReturnType<IGetState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type IAppDispatch = typeof store.dispatch;

export type { AnyAction };

export default store;
