/** Vendors */
import { Button, Popconfirm, Popover } from "antd";

/** Custom Hooks */
import useAccess from "@hooks/useAccess";

/** Enums */
import { BroadcastStatus, ResourceId, Tool } from "types";

/** Types */
import type { IBroadcast, IListRowOptions } from "types";

interface IOptions extends IListRowOptions<IBroadcast> {
  actions: IListRowOptions<IBroadcast>["actions"] & {
    copy: (broadcast: IBroadcast) => () => void;
  };
}

function Options({ actions, children, details }: IOptions) {
  const hasAccess = useAccess({
    access_key: "delete-broadcast",
    resource_id: ResourceId.Broadcast,
    resource_name: details?.resource_name,
    tool: Tool.Broadcast,
  });

  return (
    <Popover
      content={
        <div className="flex-column" style={{ maxWidth: 160 }}>
          <Button block onClick={actions?.copy(details)} type="text">
            Create Duplicate
          </Button>
          {details.status === BroadcastStatus.Unsent && (
            <Button block onClick={actions.toggle(details)} type="text">
              Edit Broadcast
            </Button>
          )}
          {hasAccess && (
            <Button block danger type="text">
              <Popconfirm
                title="Are you sure delete this broadcast?"
                onConfirm={actions.delete(details)}
                okText="Yes"
                cancelText="No"
              >
                Delete Broadcast
              </Popconfirm>
            </Button>
          )}
        </div>
      }
      placement="left"
    >
      {children}
    </Popover>
  );
}

export default Options;
