/** Vendors */
import { PlusOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

/** Custom Components */
import SyncLoader from "../../shared/loading/Sync";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { IListHeader, IRecording } from "types";

function ListHeader({ actions, details, loading }: IListHeader<IRecording>) {
  return (
    <div className="header">
      <div className="item">
        <div className="left-column">
          <div className="title">{details.name}</div>
        </div>
        <div className="right-column">
          <SyncLoader actions={actions} loading={loading} />
          <Tooltip placement="left" title="Create Transcription">
            <Button
              className="option hoverable recording-create"
              onClick={actions.toggle({
                resource_id: ResourceId.Recording,
              } as IRecording)}
              size="small"
              type="text"
            >
              <PlusOutlined style={{ color: "#aaa", fontSize: 14 }} />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ListHeader;
