import React, { lazy } from "react";

/** Vendors */
import { Navigate } from "react-router-dom";

/* User Screens */
const Profile = lazy(() => import("../components/profile/views/Profile"));

/* Settings, Management Of Application */
const Metadata = lazy(() => import("../components/management/views/Overview"));

/* Auth Screens */
const Signout = lazy(() => import("../components/auth/views/Signout"));

const AppRoutes = [
  {
    index: true,
    key: "ims-app-route-0",
    lazy: async function () {
      const Component = await import("../components/home/views/Home");
      return { Component: Component.default };
    },
  },
  {
    key: "ims-app-route-1",
    lazy: async function () {
      const Component = await import("../components/chat/views/Overview");
      return { Component: Component.default };
    },
    path: "/chat",
  },
  {
    key: "ims-app-route-2",
    lazy: async function () {
      const Component = await import("../components/contacts/views/Overview");
      return { Component: Component.default };
    },
    path: "/contacts",
  },
  {
    element: (
      <Metadata
        helmet={{ name: "Metadata Management", title: "Metadata Mmgt - IMS" }}
      />
    ),
    key: "ims-app-route-3",
    path: "/apps/metadata",
  },
  {
    element: (
      <Profile helmet={{ name: "My Profile", title: "My Profile - IMS" }} />
    ),
    key: "ims-app-route-4",
    path: "/profile",
  },
  {
    element: <Signout helmet={{ name: "Signout", title: "Signout - IMS" }} />,
    key: "ims-app-route-5",
    path: "/signout",
  },
  {
    element: <Navigate path="/development" to="/" />,
    key: "ims-app-route-6",
    name: "redirect",
    title: "redirect",
  },
  {
    element: <Navigate path="*" to="/" />,
    key: "ims-app-route-7",
    path: "*",
  },
];

export default AppRoutes;
