import { lazy, useEffect } from "react";

/** Vendors */
import { Outlet } from "react-router-dom";
import { App, ConfigProvider, Layout, theme } from "antd";

/** Custom Components */
import Navbar from "./components/shared/navbar/Navbar";

/** Classification Banner */
import Banner from "./components/shared/banner/Banner";

/** Lazy Loaded Routes */
import AppBoundary from "./components/auth/views/Boundary";

/** Lazy Loaded Supporting Components */
const LeftSidebar = lazy(() => import("./components/shared/sidebar/Sidebar"));
const AppModals = lazy(() => import("./components/shared/modal/Modals"));
const Notification = lazy(
  () => import("./components/shared/notification/Notification")
);
const Sidebars = lazy(() => import("./components/shared/sidebar/Sidebars"));

//https://ant.design/docs/react/customize-theme
import appTheme from "@dist/theme/app.json";

function IMSApp() {
  useEffect(() => {
    window.global = window;
  }, []);

  return (
    <ConfigProvider theme={{ ...appTheme, algorithm: theme.darkAlgorithm }}>
      <App>
        <AppBoundary>
          <Banner />
          <Layout className="m-auto" hasSider={true}>
            <LeftSidebar />
            <Layout className="site-layout">
              <Navbar />
              <Layout.Content className="p-4">
                <Outlet />
              </Layout.Content>
              <AppModals />
              <Sidebars />
              <Notification />
            </Layout>
          </Layout>
        </AppBoundary>
      </App>
    </ConfigProvider>
  );
}

export default IMSApp;
