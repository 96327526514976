/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";
import type { ILicense, IMember } from "../../types";

/** File Actions */
const license = createReducer(initialState.license, (builder) => {
  /** License Assigned To */
  builder
    .addCase(
      createAction(types.ASSIGN_LICENSE_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => ({
        ...state,
        assigned_to: redux.addResources<IMember>(
          state.assigned_to,
          action,
          "member"
        ),
      })
    )
    .addCase(
      createAction(types.GET_LICENSE_ASSIGNED_TO_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => ({
        ...state,
        assigned_to: redux.joinOrOverwrite<IMember>(state.assigned_to, action),
      })
    )
    .addCase(
      createAction(types.REASSIGN_LICENSE_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => ({
        ...state,
        assigned_to: redux.removeMatchCase<IMember>(
          state.assigned_to,
          action,
          "member"
        ),
      })
    );

  /** License Details and List */
  builder
    .addCase(
      createAction(types.CREATE_LICENSE_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => {
        return {
          ...state,
          ...redux.addResources<ILicense>(state, action, "license"),
        };
      }
    )
    .addCase(
      createAction(types.DELETE_LICENSE_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => {
        return {
          ...state,
          ...redux.removeMatchCase<ILicense>(state, action, "license"),
        };
      }
    )
    .addCase(
      createAction(types.RESET_LICENSES_SUCCESS),
      (state: IRootState["license"]) => {
        return { ...state, list: [] };
      }
    )
    .addCase(
      createAction(types.SEARCH_LICENSES_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => {
        return {
          ...state,
          ...redux.joinOrOverwrite<ILicense>(state, action),
        };
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_LICENSE_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => {
        return { ...state, details: action.license };
      }
    )
    .addCase(
      createAction(types.TOGGLE_LICENSE_MODAL),
      (state: IRootState["license"], action: AnyAction) => {
        return { ...state, details: action.license };
      }
    )
    .addCase(
      createAction(types.UPDATE_LICENSE_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => {
        return {
          ...state,
          ...redux.updateMatchCase<ILicense>(state, action, "license"),
        };
      }
    );

  /** License Metrics */
  builder
    .addCase(
      createAction(types.GET_LICENSE_METRICS_SUCCESS),
      (state: IRootState["license"], action: AnyAction) => ({
        ...state,
        metrics: { ...state.metrics, ...action.results[0] },
      })
    )
    .addDefaultCase((state) => state);
});

export default license;
