/** Types **/
import type { AnyAction, IRootState } from "../../redux/configureStore";
import type { IBasicRecord, IFile, IStandardList } from "../../types";

const reduxMethods = {
  addResources: <T extends IBasicRecord | IFile>(
    state: IStandardList<T>,
    action: AnyAction,
    key: string,
    overwrite = "resource_name"
  ): IStandardList<T> => {
    const list: IStandardList<T>["list"] = Object.values(
      [...state.list, action[key]].reduce((r: any, props: T) => {
        const value = props[overwrite as keyof typeof props];
        if (value === action[key][overwrite]) {
          r[value] = { ...props, ...action[key] };
        } else {
          r[value] = props;
        }
        return r;
      }, {})
    );
    return { ...state, details: action[key], list };
  },
  /**
   * @param state Array
   * @param action expecting { results: [] } callback from AWS WebSocket
   * @returns Array
   */
  joinOrOverwrite: <T extends IBasicRecord | IFile>(
    state: IStandardList<T>,
    action: AnyAction,
    key = "list"
  ): IStandardList<T> => {
    const join = action.dataSetKey === state.dataSetKey;
    const list = join
      ? [...state[key], ...action.results]
      : [...action.results];
    return { ...state, dataSetKey: action.dataSetKey, [key]: list };
  },
  orderResources: <T extends IBasicRecord | IFile>(
    resources: Array<T>
  ): Array<T> => {
    if (resources.length === 0) return resources;

    return resources.sort(
      (a: any, b: any) => a.timestamp! - b.timestamp! //Changed to not undef for type safety
    );
  },
  resetResources: <T extends IBasicRecord | IFile>(
    state: IStandardList<T>
  ): IStandardList<T> => {
    return { ...state, list: [] };
  },
  removeDuplicates: <T extends IBasicRecord | IFile>(
    state: IStandardList<T>,
    action: AnyAction
  ): IStandardList<T> => {
    function removeDups(r: any, props: T) {
      if (props) r[props.resource_name] = props;
      return r;
    }
    return {
      ...state,
      list: Object.values(
        [...state.list, ...(action.results || [])].reduce(removeDups, {})
      ),
    };
  },
  //Assume state is default [] structure. action is { [key]: props };
  removeMatchCase: <T extends IBasicRecord | IFile>(
    state: IStandardList<T>,
    action: AnyAction,
    key: string,
    overwrite = "resource_name"
  ): IStandardList<T> => {
    const removeMatch = (props: T) => {
      const value = overwrite as keyof typeof props;
      return props[value] !== action[key][value];
    };
    let details = state.details;
    if (state.details?.[overwrite] !== action?.[key]?.[overwrite]) {
      details = {};
    }
    return { ...state, details, list: state.list.filter(removeMatch) };
  },
  /**
   * @description Toggle modals, sidebars, etc.
   */
  toggleState: (
    state: IRootState["toggle"],
    action: AnyAction,
    key: string
  ) => {
    return { ...state, [key]: action.show === false ? false : !state[key] };
  },
  /** @description Update property in details and list */
  updateMatchCase: <T extends IBasicRecord | IFile>(
    state: IStandardList<T>,
    action: AnyAction,
    key: string,
    overwrite = "resource_name"
  ): IStandardList<T> => {
    const replaceMatch = (props: T): T => {
      const value = overwrite as keyof typeof props;
      return props[value] === action[key][value]
        ? { ...props, ...action[key] }
        : props;
    };

    return {
      ...state,
      details: { ...state.details, ...action[key] },
      list: state.list.map(replaceMatch),
    };
  },
};

export default reduxMethods;
