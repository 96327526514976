/** Vendors */
import axios from "axios";

/** Types */
import type { ISearchMetadata } from "../types";

const searchMetadata = async function (params: ISearchMetadata) {
  return await axios.get("/metadata/search", { params });
};

const metadata = {
  search: searchMetadata,
};

export default metadata;
