const convertedVapidKey = urlBase64ToUint8Array(
  import.meta.env.VITE_REACT_APP_PUSH_KEY as string
);

//Web Push Subscription.
export async function getWebPushSubscription() {
  return await navigator.serviceWorker.ready.then((sw) =>
    sw.pushManager.getSubscription()
  );
}

export async function subscribeToNotifications() {
  return await navigator.serviceWorker.ready.then((sw) =>
    sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey,
    })
  );
}

export async function unsubscribeToNotifications() {
  return await navigator.serviceWorker.ready
    .then((sw) => sw.pushManager.getSubscription())
    .then((subscription) => {
      subscription?.unsubscribe();
      return subscription;
    });
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
