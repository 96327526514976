/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";
import type { IAnyObject, ISearch } from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function resetSearchAction() {
  return (dispatch: IAppDispatch) => {
    dispatch({ type: types.RESET_SEARCH_SUCCESS });
  };
}

export function searchHierarchyAction(search: ISearch) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "search",
      method: "GET",
      path: "search",
      search: {
        ...search,
        name: "hierarchy",
      },
      type: types.SEARCH_HIERARCHY_SUCCESS,
    });
  };
}

export function updateSearchAction(search: Partial<ISearch>) {
  return (dispatch: IAppDispatch) => {
    dispatch({ search, type: types.UPDATE_SEARCH_SUCCESS });
  };
}
