/** Vendors */
import { Hub } from "aws-amplify/utils";

/** Redux */
import * as types from "../actions/types";

/** Types */
import type { AnyAction } from "@reduxjs/toolkit";
import type { Dispatch, Middleware } from "redux";

const notificationMiddleware: Middleware<
  object,
  any,
  Dispatch<AnyAction>
> = () => {
  return (next) => (action: any) => {
    switch (action.type) {
      case types.TOGGLE_NOTIFICATION:
        const data = JSON.parse(action.notification.data || "{}");
        console.log(data);
        Hub.dispatch("notification", {
          data,
          event: data?.type || "success", // 'success' | 'info' | 'warning' | 'error'
        });
        break;
      default:
        next(action);
    }
  };
};

export default notificationMiddleware;
