import ReactDOM from "react-dom/client";
import { StrictMode } from "react";

/** Vendors */
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

/** Custom Components */
import AllRoutes from "./routes";
import SiteErrorBoundary from "./components/shared/error/SiteBoundary";

/** Redux */
import store from "./redux/configureStore";

/* Axios Config */
import "./dist/js/setup";

/* Custom CSS Config */
import "./dist/css/app.css";
import "./dist/css/buttons.css";
import "./dist/css/card.css";
import "./dist/css/colors.css";
import "./dist/css/form.css";
import "./dist/css/format.css";
import "./dist/css/loading.css";
import "./dist/css/map.css";
import "./dist/css/table.css";
import "./dist/css/text.css";

/** Mobile app redirect */
if (window.innerWidth < 769 && import.meta.env.META === "production") {
  window.location.href =
    "https://app.imstools.us" +
    window.location.pathname +
    window.location.search;
}

const router = createBrowserRouter(AllRoutes);
const root = document.getElementById("root");

/** Pierre Testing the broadcast channel - START */
const broadcast = new BroadcastChannel("push-notifications");
broadcast.onmessage = function (event) {
  console.log(
    "Received data emitted from SW thru Broadcast channel:",
    event.data.data
  );
  //We should dispatch to the store?

  store.dispatch({
    payload: event.data.payload,
    type: event.data.type,
  });
};
/** Pierre Testing the service worker - END */

ReactDOM.createRoot(root!).render(
  <StrictMode>
    <Auth0Provider
      authorizationParams={{
        redirect_uri: import.meta.env.VITE_REACT_APP_DOMAIN,
      }}
      clientId={import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID}
      domain={import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN}
    >
      <ReduxProvider store={store}>
        <SiteErrorBoundary>
          <RouterProvider router={router} />
        </SiteErrorBoundary>
      </ReduxProvider>
    </Auth0Provider>
  </StrictMode>
);
