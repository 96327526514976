/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";

/** File Actions */
const socket = createReducer(initialState.socket, (builder) => {
  builder
    .addCase(createAction(types.SOCKET_CONNECTED), () => ({
      status: "connected",
    }))
    .addCase(createAction(types.SOCKET_DISCONNECTED), () => ({
      status: "disconnected",
    }))
    .addCase(createAction(types.SOCKET_RECONNECT), () => ({
      status: "reconnect",
    }))
    .addDefaultCase((state) => state);
});

export default socket;
