/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";

import type {
  IAnyObject,
  IMember,
  ISearchMembers,
  IShareMember,
} from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function createContactAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "PUT",
      path: "member",
      type: types.CREATE_CONTACT_SUCCESS,
    });
  };
}

export function deleteContactAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "DELETE",
      path: "member",
      type: types.DELETE_CONTACT_SUCCESS,
    });
  };
}

export function getContactAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "GET",
      path: "member",
      type: types.GET_CONTACT_SUCCESS,
    });
  };
}

export function inviteContactsAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "POST",
      path: "member/invite",
      type: types.INVITE_CONTACTS_SUCCESS,
    });
  };
}

export function resetContactsAction() {
  return (dispatch: IAppDispatch) => {
    dispatch({
      type: types.RESET_CONTACTS_SUCCESS,
    });
  };
}

export function setActiveContactAction(member: IMember) {
  return (dispatch: IAppDispatch) => {
    dispatch({
      member,
      type: types.SET_ACTIVE_CONTACT_SUCCESS,
    });
  };
}

export function searchContactsAction(search: ISearchMembers) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      method: "GET",
      path: "member/search",
      search,
      type: types.SEARCH_CONTACTS_SUCCESS,
    });
  };
}

export function shareContactAction(details: IShareMember) {
  return (dispatch: IAppDispatch) => {
    sendMessage(dispatch)({
      action: "member",
      path: "member/share",
      member: details.member,
      share: details.share,
      type: types.UPDATE_CONTACT_SUCCESS,
    });
  };
}

export function updateContactAction(member: IMember) {
  return async function (dispatch: IAppDispatch) {
    sendMessage(dispatch)({
      action: "member",
      member,
      method: "POST",
      path: "member",
      type: types.UPDATE_CONTACT_SUCCESS,
    });
  };
}
