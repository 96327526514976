import React, { useEffect, useRef, useState } from "react";

/** Vendors */
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";

/** Redux */
import { updateSearchAction } from "@redux/actions/search";

/** Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

import { filterByText, formatName, removeDuplicates } from "@dist/js/support";

/** General Search For Any Table, Card, Etc. Smaller scope than navbar search. */
function AdvancedSearch({ active, actions, data = [], details, setIsActive }) {
  /** Step . If data available is passed, create scoped search criteria. */
  const [available, setAvailable] = useState({
    billets: [],
    contacts: [],
    data: [],
    moss: [],
    organizations: [],
    ranks: [],
    sections: [],
  });
  const [recommended, setRecommended] = useState([]);
  const dispatch = useAppDispatch();
  const timerRef = useRef();

  const { contacts, metadata } = useAppSelector((state) => ({
    contacts: state.contact.list,
    metadata: state.metadata,
  }));

  /** Step 1. On initial mount, clear text searching. */
  useEffect(() => {
    const params = {
      active: active || "contacts",
      is_top_level: false,
      text: "",
    };
    dispatch(updateSearchAction(params));
  }, []);

  /** Step 2. If the active key changes (reusable component elsewhere clear prefilter) */
  useEffect(() => {
    setRecommended([]);
    setAvailable({
      billets: [],
      contacts: [],
      data: [],
      moss: [],
      organizations: [],
      ranks: [],
      sections: [],
    });
  }, [active]);

  useEffect(() => {
    /** Breakout matching values from common search criteria */
    let match = data.reduce(
      (acc, props) => {
        /** Billets */
        if (props.billet || props.billets) {
          let billets = [props.billet, ...(props.billets || [])].filter(
            (a) => a
          );
          acc.b = acc.b.concat(billets);
        }

        /** Contacts */
        if (
          props.assigned_to ||
          props.author ||
          props.member ||
          props.owner ||
          props.participants ||
          props.reviewer
        ) {
          let users = [
            props.assigned_to,
            props.author,
            props.member,
            props.owner,
            props.reviewer,
            ...(props.participants || []),
          ].filter((a) => a);
          acc.m = acc.m.concat(users);
        }

        /** MOSS */
        if (props.mos || props.moss) {
          let ms = Array.isArray(props.mos) ? props.mos : [props.moss];
          let moss = [...ms, ...(props.moss || [])].filter((a) => a);
          acc.moss = acc.moss.concat(moss);
        }

        /** Organizations */
        if (props.organization || props.organizations) {
          let orgs = [
            props.organization,
            ...(props.organizations || []),
          ].filter((a) => a);
          acc.o = acc.o.concat(orgs);
        }

        /** Ranks */
        if (props.rank || props.ranks) {
          let r1 = Array.isArray(props.rank) ? props.rank : [props.rank];
          let rs = [...r1, ...(props.ranks || [])].filter((a) => a);
          acc.r = acc.r.concat(rs);
        }

        /** Staff Sections */
        if (props.section || props.sections) {
          let ss = [props.section, ...(props.sections || [])].filter((a) => a);
          acc.s = acc.s.concat(ss);
        }
        return acc;
      },
      { b: [], m: [], moss: [], o: [], r: [], s: [] }
    );

    setAvailable({
      billets: match.b
        .map(justResourceName)
        .map(findObject("billets"))
        .filter((a) => a),
      data,
      contacts: match.m
        .map(justResourceName)
        .map(findObject("contacts"))
        .filter((a) => a),
      moss: match.moss
        .map(justResourceName)
        .map(findObject("moss"))
        .filter((a) => a),
      organizations: match.o
        .map(justResourceName)
        .map(findObject("organizations"))
        .filter((a) => a),
      ranks: match.r
        .map(justResourceName)
        .map(findObject("ranks"))
        .filter((a) => a),
      sections: match.s
        .map(justResourceName)
        .map(findObject("sections"))
        .filter((a) => a),
    });
  }, [data]);

  const findMatch = (text = "") => {
    try {
      let search0 = filterByText(data, text);
      let search1 = filterByText(available.billets || [], text);
      let search2 = filterByText(available.contacts || [], text);
      let search3 = filterByText(available.moss || [], text);
      let search4 = filterByText(available.organizations || [], text);
      let search5 = filterByText(available.ranks || [], text);
      let search6 = filterByText(available.sections || [], text);

      return [
        ...search0,
        ...search1,
        ...search2,
        ...search3,
        ...search4,
        ...search5,
        ...search6,
      ];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  //Pull out 'resource_name' from passed parameter
  const justResourceName = (s) => (typeof s === "string" ? s : s.resource_name);

  const options = recommended
    .map((props) => (
      <Select.Option key={props.resource_name} value={props.resource_name}>
        {props.given_name ? formatName(props) : props?.name}
      </Select.Option>
    ))
    .slice(0, 5);

  const onClear = () => {
    if (typeof actions.onClear === "function") {
      actions.onClear();
    }
  };
  const onChange = (text) => {
    if (typeof actions.onSearch === "function") {
      actions.onSearch(text);
    }
  };

  const onSearch = (text) => {
    clearTimeout(timerRef.current);
    /** Step 1. Look internal. */
    const match = findMatch(text);
    setRecommended(removeDuplicates(match));
  };

  const onSetIsActive = (next) => () => {
    if (typeof setIsActive === "function") {
      setIsActive(next);
    }
  };

  //Match passed 'resource_name' with original object.
  const findObject = (key) => (resource_name) => {
    if (key === "contacts") {
      return contacts.find((m) => m.resource_name === resource_name);
    }
    return metadata[key].all.find((m) => m.resource_name === resource_name);
  };

  return (
    <Select
      allowClear
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
      onBlur={onSetIsActive(false)}
      onChange={onChange}
      onClear={onClear}
      onFocus={onSetIsActive(true)}
      onSearch={onSearch}
      placeholder={details.placeholder || "Search..."}
      size="large"
      showSearch
      suffixIcon={<SearchOutlined style={{ color: "#ccc" }} />}
    >
      {options}
    </Select>
  );
}

export default AdvancedSearch;
