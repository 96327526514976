import React from "react";

import App from "../App";
import AppRoutes from "./AppRoutes";
import BroadcastRoutes from "./BroadcastRoutes";
import RecordingRoutes from "./RecordingRoutes";

const AllRoutes = [
  {
    element: <App />,
    children: [...AppRoutes, ...BroadcastRoutes, ...RecordingRoutes],
    path: "/",
  },
];

export default AllRoutes;
