/** Enums */
import { DeviceView, ResourceId, Tool } from "../../types";

/** Types */
import {
  IAnyObject,
  IBarracksMetrics,
  IBase,
  IBasicRecord,
  IBillet,
  IBroadcast,
  IBroadcastMetrics,
  IBroadcastResponse,
  IBuilding,
  IChannel,
  ICheckin,
  ICheckinStep,
  IDefinition,
  IDuty,
  IExercise,
  IFile,
  ILicense,
  ILicenseProducts,
  IMember,
  IMoss,
  INotification,
  INotificationTemplate,
  IOrganization,
  IPreference,
  IProduct,
  IRFDGroup,
  IRFDMetrics,
  IRFDRecall,
  IRFDRequirement,
  IRank,
  IRecording,
  IRoom,
  ISearch,
  ISection,
  IStandardList,
  ISurvey,
  ISurveyForm,
  ITicket,
  ITicketCategory,
  ITicketSubcategory,
  IToggle,
} from "../../types";

export interface IInitialState {
  access: { [k: string]: boolean };
  barracks: {
    duty: IStandardList<IDuty>;
    metrics: IBarracksMetrics;
  };
  broadcast: IStandardList<IBroadcast> & {
    metrics: IBroadcastMetrics;
    response: IStandardList<IBroadcastResponse>;
  };
  checkin: IStandardList<ICheckin> & { step: IStandardList<ICheckinStep> };
  channel: IStandardList<IChannel>;
  contact: IStandardList<IMember>;
  device: {
    dod_email_verified: boolean;
    dod_email_timestamp: number;
    install: boolean;
    on_line: typeof navigator.onLine;
    mobile: boolean;
    notification: INotification;
    now: number;
    subview: DeviceView;
    tour: {
      app: Tool;
      start: boolean;
    };
  };
  file: IStandardList<IFile>;
  license: IStandardList<ILicense> & {
    assigned_to: IStandardList<IMember>;
    metrics: {
      assigned: number;
      organizations: IOrganization[];
      products: ILicenseProducts[];
      unassigned: number;
    };
  };
  member: IStandardList<IMember>;
  metadata: {
    base: IStandardList<IBase>;
    billet: IStandardList<IBillet>;
    building: IStandardList<IBuilding>;
    definition: IStandardList<IDefinition>;
    details: { resource_id: ResourceId };
    exercise: IStandardList<IExercise>;
    mos: IStandardList<IMoss>;
    organization: IStandardList<IOrganization>;
    product: IStandardList<IProduct>;
    rank: IStandardList<IRank>;
    room: IStandardList<IRoom>;
    section: IStandardList<ISection>;
  };
  notification: {
    [channel: string]: IStandardList<INotification>;
  };
  preference: IStandardList<IPreference>;
  recording: IStandardList<IRecording>;
  rfd: {
    group: IStandardList<IRFDGroup>;
    metrics: IRFDMetrics;
    recall: IStandardList<IRFDRecall>;
    requirement: IStandardList<IRFDRequirement>;
  };
  search: ISearch;
  socket: { status: "connected" | "disconnected" | string };
  survey: IStandardList<ISurvey> & {
    form: IStandardList<ISurveyForm>;
    metrics: any;
  };
  template: IStandardList<INotificationTemplate>;
  ticket: IStandardList<ITicket> & {
    category: IStandardList<ITicketCategory>;
    history: IStandardList<INotification>;
    subcategory: IStandardList<ITicketSubcategory>;
  };
  toggle: IToggle;
}

const initialState: IInitialState = {
  access: {},
  barracks: {
    duty: {
      details: {} as IStandardList<IDuty>["details"],
      list: [],
    },
    metrics: {
      duty: {},
      requests: { members: {}, time: {}, type: {} },
      rooms: {},
    } as IBarracksMetrics,
  },
  broadcast: {
    details: {} as IStandardList<IBroadcast>["details"],
    list: [],
    metrics: {
      broadcast: {} as IStandardList<IBroadcast>["details"],
      mediums: [],
      total: { sent: 0, unsent: 0 },
    } as IBroadcastMetrics,
    response: {
      details: {} as IStandardList<IBroadcastResponse>["details"],
      list: [],
    },
  },
  checkin: {
    details: {} as IStandardList<ICheckin>["details"],
    list: [],
    step: {
      details: {} as IStandardList<ICheckinStep>["details"],
      list: [],
    },
  },
  channel: {
    details: {} as IStandardList<IChannel>["details"],
    list: [],
  },
  contact: {
    details: {} as IStandardList<IMember>["details"],
    list: [],
  },
  device: {
    dod_email_verified: false,
    dod_email_timestamp: 0,
    install: false,
    on_line: navigator.onLine,
    mobile: window.innerWidth < 768,
    notification: {} as INotification,
    now: new Date().getTime(),
    subview: DeviceView.Conversation,
    tour: {
      app: Tool.RFD,
      start: false,
    },
  },
  file: {
    details: {} as IStandardList<IFile>["details"],
    list: [],
  },
  license: {
    assigned_to: {
      details: {} as IStandardList<IMember>["details"],
      list: [],
    },
    details: {} as IStandardList<ILicense>["details"],
    list: [],
    metrics: {
      assigned: 0,
      organizations: [],
      products: [],
      unassigned: 0,
    },
  },
  member: {
    details: {} as IStandardList<IMember>["details"],
    list: [],
  },
  metadata: {
    base: {
      details: {} as IStandardList<IBase>["details"],
      list: [],
    },
    billet: {
      details: {} as IStandardList<IBillet>["details"],
      list: [],
    },
    building: {
      details: {} as IStandardList<IBuilding>["details"],
      list: [],
    },
    definition: {
      details: {} as IStandardList<IDefinition>["details"],
      list: [],
    },
    details: { resource_id: ResourceId.Base },
    exercise: {
      details: {} as IStandardList<IExercise>["details"],
      list: [],
    },
    mos: {
      details: {} as IStandardList<IMoss>["details"],
      list: [],
    },
    organization: {
      details: {} as IStandardList<IOrganization>["details"],
      list: [],
    },
    product: {
      details: {} as IStandardList<IProduct>["details"],
      list: [],
    },
    rank: {
      details: {} as IStandardList<IRank>["details"],
      list: [],
    },
    room: {
      details: {} as IStandardList<IRoom>["details"],
      list: [],
    },
    section: {
      details: {} as IStandardList<ISection>["details"],
      list: [],
    },
  },
  notification: {} as IInitialState["notification"],
  preference: {
    details: {} as IStandardList<IPreference>["details"],
    list: [],
  },
  recording: {
    details: {} as IStandardList<IRecording>["details"],
    list: [],
  },
  rfd: {
    group: {
      details: {} as IStandardList<IRFDGroup>["details"],
      list: [],
    },
    metrics: {
      groups: [] as IBasicRecord[],
      participants: [] as IMember[],
      recall: {
        medium: {} as any,
        timeliness: {} as any,
      },
      responses: [] as IBasicRecord[],
      requirement: {
        metrics_by_group: [] as IBasicRecord[],
        metrics_by_organization: [] as IBasicRecord[],
        mos: {} as IMoss,
        organizations: {} as IOrganization,
        rank: {} as IRank,
        total: 0,
        verified: 0,
      },
      rollup: [],
    } as IRFDMetrics,
    recall: {
      details: {} as IStandardList<IRFDRecall>["details"],
      list: [],
    },
    requirement: {
      details: {} as IStandardList<IRFDRequirement>["details"],
      list: [],
    },
  },
  search: {
    active: Tool.Contacts,
    details: {} as IAnyObject,
    hierarchy: {
      children: [] as IOrganization[],
      member: {} as IMember,
      organization: {} as IOrganization,
      parent: {} as IOrganization,
    },
    is_top_level: false,
    list: [],
    placeholder: "Search for contacts...",
    text: "",
  },
  socket: { status: "disconnected" },
  survey: {
    details: {} as IStandardList<ISurvey>["details"],
    form: {
      details: {} as IStandardList<ISurveyForm>["details"],
      list: [],
    },
    list: [],
    metrics: {},
  },
  template: {
    details: {} as IStandardList<INotificationTemplate>["details"],
    list: [],
  },
  ticket: {
    details: {} as IStandardList<ITicket>["details"],
    category: {
      details: {} as IStandardList<ITicketCategory>["details"],
      list: [],
    },
    history: {
      details: {} as IStandardList<INotification>["details"],
      list: [],
    },
    list: [],
    metrics: {},
    subcategory: {
      details: {} as IStandardList<ITicketSubcategory>["details"],
      list: [],
    },
  },
  toggle: {
    aarModal: false,
    barracksDutyModal: false,
    barracksRoomModal: false,
    broadcastModal: false,
    channelModal: false,
    channelSidebar: false,
    checkinModal: false,
    checkinStepModal: false,
    contactModal: false,
    contactSidebar: false,
    dutyModal: false,
    barracksMaintenanceModal: false,
    filterModal: false,
    inviteMembersModal: false,
    leftSidebar: false,
    licenseModal: false,
    licenseRequestModal: false,
    metadataModal: false,
    notification: false,
    notificationTemplateModal: false,
    rfdGroupModal: false,
    rfdRecallModal: false,
    rfdRecallResponseModal: false,
    rfdRecallRollupModal: false,
    rfdRequirementModal: false,
    rfdRequirementVerificationModal: false,
    rfdRosterModal: false,
    surveyModal: false,
    ticketModal: false,
    ticketCategoryModal: false,
    transcribeModal: false,
  },
};

export default initialState;
