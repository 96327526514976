/** Vendors */
import { Spin } from "antd";

function LoadingScreen() {
  return (
    <div className="w-100 h-100 flex-1 flex-around flex-center">
      <Spin size="large" />
    </div>
  );
}

export default LoadingScreen;
