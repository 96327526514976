/** Vendors */
import { combineReducers } from "@reduxjs/toolkit";

/** Reducers */
import access from "./access";
import barracks from "./barracks";
import broadcast from "./broadcast";
import channel from "./channel";
import checkin from "./checkin";
import contact from "./contact";
import device from "./device";
import file from "./file";
import license from "./license";
import member from "./member";
import metadata from "./metadata";
import notification from "./notification";
import preference from "./preference";
import recording from "./recording";
import rfd from "./rfd";
import search from "./search";
import socket from "./socket";
import survey from "./survey";
import template from "./template";
import ticket from "./ticket";
import toggle from "./toggle";

const rootReducer = combineReducers({
  access,
  barracks,
  broadcast,
  channel,
  checkin,
  contact,
  device,
  file,
  license,
  member,
  metadata,
  notification,
  preference,
  recording,
  rfd,
  search,
  socket,
  survey,
  template,
  toggle,
  ticket,
});

export default rootReducer;
