/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";
import type { ISurvey, ISurveyForm } from "../../types";

/** File Actions */
const survey = createReducer(initialState.survey, (builder) => {
  /** Survey Details */
  builder
    .addCase(
      createAction(types.CREATE_SURVEY_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => {
        return redux.addResources<ISurvey>(state, action, ResourceId.Survey);
      }
    )
    .addCase(
      createAction(types.DELETE_SURVEY_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => {
        return redux.removeMatchCase<ISurvey>(state, action, "survey");
      }
    )
    .addCase(
      createAction(types.GET_SURVEY_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => {
        return { ...state, details: action.survey };
      }
    )
    .addCase(
      createAction(types.SEND_SURVEY_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => {
        return { ...state, details: action.survey };
      }
    )
    .addCase(
      createAction(types.SEARCH_SURVEYS_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => {
        return redux.joinOrOverwrite<ISurvey>(state, action);
      }
    )
    .addCase(
      createAction(types.TOGGLE_SURVEY_MODAL),
      (state: IRootState["survey"], action: AnyAction) => {
        return { ...state, details: action.survey };
      }
    )
    .addCase(
      createAction(types.UPDATE_SURVEY_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => {
        return redux.updateMatchCase<ISurvey>(state, action, "survey");
      }
    );

  /** Survey Form Values? */
  builder
    .addCase(
      createAction(types.CREATE_SURVEY_FORM_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => ({
        ...state,
        form: redux.addResources<ISurveyForm>(state.form, action, "form"),
      })
    )
    .addCase(
      createAction(types.DELETE_SURVEY_FORM_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => ({
        ...state,
        form: redux.removeMatchCase<ISurveyForm>(state.form, action, "form"),
      })
    )
    .addCase(
      createAction(types.GET_SURVEY_FORM_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => ({
        ...state,
        form: redux.updateMatchCase<ISurveyForm>(state.form, action, "form"),
      })
    )
    .addCase(
      createAction(types.SEARCH_SURVEY_FORMS_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => ({
        ...state,
        form: redux.joinOrOverwrite<ISurveyForm>(state.form, action),
      })
    )
    .addCase(
      createAction(types.UPDATE_SURVEY_FORM_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => ({
        ...state,
        form: redux.updateMatchCase<ISurveyForm>(state.form, action, "survey"),
      })
    );

  /** Survey Metrics */
  builder
    .addCase(
      createAction(types.SEARCH_SURVEY_METRICS_SUCCESS),
      (state: IRootState["survey"], action: AnyAction) => ({
        ...state,
        metrics: action.metrics,
      })
    )
    .addDefaultCase((state) => state);
});

export default survey;
