/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { IRecording } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const recording = createReducer(initialState.recording, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_RECORDING_SUCCESS),
      (state: IRootState["recording"], action: AnyAction) => {
        return redux.addResources<IRecording>(
          state,
          action,
          ResourceId.Recording
        );
      }
    )
    .addCase(
      createAction(types.DELETE_RECORDING_SUCCESS),
      (state: IRootState["recording"], action: AnyAction) => {
        return redux.removeMatchCase<IRecording>(
          state,
          action,
          ResourceId.Recording
        );
      }
    )
    .addCase(
      createAction(types.GET_RECORDING_SUCCESS),
      (state: IRootState["recording"], action: AnyAction) => {
        return { ...state, details: action.recording };
      }
    )
    .addCase(
      createAction(types.SEARCH_RECORDINGS_SUCCESS),
      (state: IRootState["recording"], action: AnyAction) => {
        return redux.joinOrOverwrite<IRecording>(state, action);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_RECORDING_SUCCESS),
      (state: IRootState["recording"], action: AnyAction) => {
        return { ...state, details: action.recording };
      }
    )
    .addCase(
      createAction(types.TOGGLE_TRANSCRIBE_MODAL),
      (state: IRootState["recording"], action: AnyAction) => {
        return { ...state, details: action.details || {} };
      }
    )
    .addCase(
      createAction(types.UPDATE_RECORDING_SUCCESS),
      (state: IRootState["recording"], action: AnyAction) => {
        return redux.updateMatchCase<IRecording>(
          state,
          action,
          ResourceId.Recording
        );
      }
    )
    .addDefaultCase((state) => state);
});

export default recording;
