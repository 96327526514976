import React from "react";

/** Broadcast Tools */
import BroadcastChannels from "../components/broadcast/views/Channels";
import BroadcastOverview from "../components/broadcast/views/Overview";

const BroadcastRoutes = [
  {
    element: (
      <BroadcastOverview
        helmet={{ name: "Broadcast | Overview", title: "Broadcast - IMS" }}
      />
    ),
    key: "ims-broadcast-route-0",
    path: "apps/broadcast",
  },
  {
    element: (
      <BroadcastChannels
        helmet={{
          name: "Broadcast | Channels",
          title: "Broadcast Channels - IMS",
        }}
      />
    ),
    key: "ims-broadcast-route-1",
    path: "apps/broadcast/channels",
  },
];

export default BroadcastRoutes;
