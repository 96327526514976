export enum App {
  Native = "ims-native-app",
  Web = "ims-web-app",
}

export enum AsyncStorageKey {
  Auth = "auth",
  DeepLink = "deeplink",
  Device = "device",
  User = "user",
}

export enum AuthStatus {
  SignIn = "signIn",
}

export enum BroadcastStatus {
  Archived = "archived",
  Locked = "locked",
  Sent = "sent",
  Unsent = "unsent",
}

export enum BucketName {
  Public = "assets.imstools.us",
  Dev = "dev.imstools.us",
  Prod = "prod.imstools.us",
}

export enum ChannelStatus {
  Active = "active",
  Inactive = "inactive",
  Locked = "locked",
}

export enum ChannelType {
  Conversation = "conversation",
  Room = "room",
  Tool = "tool",
}

export enum DeviceView {
  Conversation = "conversation",
}

export enum MemberStatus {
  Active = "active",
  Pending = "pending",
}

export enum NotificationStatus {
  Active = "active",
  Accepted = "accepted",
  Complete = "complete",
  Delivered = "delivered",
  Failed = "failed",
  Queued = "queued",
  Read = "read",
  Received = "received",
  Receiving = "receiving",
  Sending = "sending",
  Sent = "sent",
  Undelivered = "undelivered",
  Unsent = "unsent",
}

export enum ResourceId {
  AAR = "aar",
  Article = "article",
  Base = "base",
  Billet = "billet",
  Broadcast = "broadcast",
  Building = "building",
  Channel = "channel",
  Category = "category",
  Definition = "definition",
  Duty = "duty",
  Exercise = "exercise",
  License = "license",
  Member = "member",
  /** Placeholder to represent all metadata, don't actually use the enum name though */
  Metadata = "metadata",
  MOS = "mos",
  Notification = "notification",
  NotificationTemplate = "notification-template",
  Organization = "organization",
  Preference = "preference",
  Product = "product",
  RFDGroup = "rfd-group",
  RFDRecall = "rfd-recall",
  RFDRequirement = "rfd-requirement",
  Rank = "rank",
  Recording = "recording",
  Room = "room",
  Section = "section",
  Template = "template",
  TroubleTicket = "ticket",
}

export enum ToggleResource {
  AARModal = "aar-modal",
  BroadcastModal = "broadcast-modal",
  BroadcastGroupModal = "broadcast-group-modal",
  ChannelModal = "channel-modal",
  ChannelSidebar = "channel-sidebar",
  CheckinModal = "checkin-modal",
  CheckinStepModal = "checkin-step-modal",
  ContactModal = "contact-modal",
  ContactSidebar = "contact-sidebar",
  BarracksDutyModal = "barracks-duty-modal",
  BarracksMaintenanceModal = "barracks-maintenance-modal",
  BarracksRoomModal = "barracks-room-modal",
  FilterModal = "filter-modal",
  LicenseModal = "license-modal",
  LicenseRequestModal = "license-request-modal",
  LeftSidebar = "left-sidebar",
  MemberInvite = "invite-members",
  MetadataModal = "metadata-modal",
  NotificationTemplateModal = "notification-template-modal",
  RFDGroupModal = "rfd-group-modal",
  RFDRecallModal = "rfd-recall-modal",
  RFDRecallResponseModal = "rfd-recall-response-modal",
  RFDRecallRollupModal = "rfd-recall-rollup-modal",
  RFDRequirementModal = "rfd-requirement-modal",
  RFDRosterModal = "rfd-roster-modal",
  RFDRequirementVerifyModal = "rfd-requirement-verify-modal",
  SurveyModal = "survey-modal",
  TicketModal = "ticket-modal",
  TicketCategoryModal = "ticket-category-modal",
  Tour = "tour",
  TranscribeModal = "transcribe-modal",
}

export enum Timezone {
  East = "America/New_York",
  Central = "America/Chicago",
  Mountain = "America/Denver",
  West = "America/Los_Angeles",
}

export enum Tool {
  Barracks = "barracks",
  Broadcast = "broadcast",
  Contacts = "contacts",
  License = "license",
  Metadata = "metadata",
  Notification = "notification",
  RFD = "rfd",
  Ticket = "ticket",
  Transcribe = "Transcribe",
}
