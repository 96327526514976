/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { IMember } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const member = createReducer(initialState.member, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => {
        return { ...state, details: action.member };
      }
    )
    .addCase(
      createAction(types.DELETE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => {
        return redux.removeMatchCase<IMember>(state, action, "member");
      }
    )
    .addCase(
      createAction(types.GET_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => {
        return { ...state, details: action.member };
      }
    )
    .addCase(
      createAction(types.SEARCH_MEMBERS_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => {
        return redux.joinOrOverwrite<IMember>(state, action);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => {
        return { ...state, details: action.member };
      }
    )
    .addCase(
      createAction(types.TOGGLE_CONTACT_SIDEBAR),
      (state: IRootState["member"], action: AnyAction) => {
        return { ...state, details: action.details };
      }
    )
    .addCase(
      createAction(types.UPDATE_MEMBER_SUCCESS),
      (state: IRootState["member"], action: AnyAction) => {
        return redux.updateMatchCase<IMember>(state, action, "member");
      }
    )
    .addDefaultCase((state) => state);
});

export default member;
