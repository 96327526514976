import React, { useEffect, useRef, useState } from "react";

/** Vendors */
import { EllipsisOutlined } from "@ant-design/icons";
import { App, Button, Flex, Progress, Tooltip, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";

/** Redux */
import toggleAction from "@redux/actions/toggle";
import { deleteChannelAction, getChannelAction } from "@redux/actions/channel";

import {
  deleteRecordingAction,
  searchRecordingsAction,
} from "@redux/actions/recording";

/** Custom Hooks */
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom Components */
import Header from "./Header";
import Options from "./Options";

/** Custom CSS */
import "../recording.css";

/** Enums */
import { ToggleResource } from "types";

/** Types */
import type { IChannel, IRecording } from "types";

function TranscriptionList() {
  const [loading, setLoading] = useState<boolean>(true);
  const [playing, setPlaying] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement>(null);
  const [searchParams] = useSearchParams();
  const { notification } = App.useApp();

  /** Step 1. As a member searches for members, this array changes values */
  const { channels, dataSetKey, recording, recordings } = useAppSelector(
    (state) => ({
      channels: state.channel.list,
      dataSetKey: state.channel.dataSetKey,
      recording: state.recording.details,
      recordings: state.recording.list,
    })
  );

  /** Step 2. On load, check if a specific channel was passed, redirect to it if so */
  useEffect(() => {
    const channel = searchParams.get("channel");
    if (channel) {
      actions.onSelect({ resource_name: channel });
    } else {
      actions.onRefresh();
    }
    return () => {
      if (audioRef.current) {
        audioRef.current = null;
      }
    };
  }, [searchParams]);

  useEffect(() => {
    setLoading(false);
  }, [dataSetKey]);

  /** Step 3. Actions */
  const actions = {
    onBack: () => {
      navigate("/apps/recording");
    },
    delete: (channel: IChannel) => () => {
      const recordings = actions.filterRecordings(channel);
      recordings.forEach((recording: IRecording) => {
        dispatch(deleteRecordingAction(recording));
      });
      dispatch(deleteChannelAction(channel));
    },
    filterRecordings: (channel: IChannel): IRecording[] => {
      return recordings.filter((recording: IRecording) => {
        return channel.resource_name === recording.channel;
      });
    },
    onPlay: (channel: IChannel) => () => {
      const recordings = actions.filterRecordings(channel);
      const match = recordings?.[0];

      /** Step 1. If audioRef has been set see if its match to existing source */
      audioRef.current?.pause();
      setPlaying("");

      if (channel?.resource_name === playing) {
        // audioRef.current.pause();
        /** Step 2. Was match, pause and return */
        return false;
      }

      /** Step 3. If does/doesn't exist but wasn't match regardless, start a new */
      if (match) {
        if (!audioRef.current) {
          audioRef.current = new Audio(match?.endpoint);
        } else {
          audioRef.current.setAttribute("src", match.endpoint);
        }
        audioRef.current.load();
        audioRef.current.autoplay = true;
        setPlaying(channel?.resource_name);
      } else {
        notification.info({
          title:
            "No recordings yet for this channel. Please request recording to listen",
        });
        setPlaying("");
      }
    },
    onRefresh: () => {
      const params = {
        start: new Date().getTime() - 1000 * 60 * 60 * 24 * 30,
      };
      setLoading(true);
      dispatch(searchRecordingsAction(params));
    },
    onSelect: (channel: IChannel) => () => {
      dispatch(getChannelAction(channel));
    },
    toggle: (details: IRecording) => () => {
      const params = { details, resource: ToggleResource.TranscribeModal };
      dispatch(toggleAction(params));
    },
  };

  let percent = 0;
  const end = recording?.end || 0;
  const now = new Date().getTime();
  const start = recording?.start || 0;
  if (now > start && now < end) {
    const increment = (end - start) / 4;
    const delta = now - start;

    if (delta < increment * 3) {
      percent = 5;
    } else if (delta < increment * 2) {
      percent = 25;
    } else if (delta < increment * 1) {
      percent = 50;
    } else {
      percent = 75;
    }
  }

  return (
    <div className="ims-card">
      <div className="p-2">
        {percent === 0 ? null : <Progress percent={percent} showInfo={false} />}
      </div>
      <Header
        actions={actions}
        details={{ name: "Audio Transcriptions" }}
        loading={!!loading}
      />
      <div className="body">
        {channels.map((channel: IChannel) => {
          const matching = actions.filterRecordings(channel) || [];
          return (
            <div className="item" key={channel.resource_name}>
              <div
                className="left-column hoverable"
                onClick={actions.onSelect(channel)}
              >
                {channel.title} - Available Recordings ({matching.length})
              </div>
              <div className="right-column">
                <Tooltip title="Long Durations of Silence Possible">
                  <Button
                    onClick={actions.onPlay(channel)}
                    type={
                      playing === channel?.resource_name ? "primary" : "default"
                    }
                  >
                    {playing === channel?.resource_name ? (
                      <Flex align="center" gap={4}>
                        <div className="pulse active" />
                        <Typography.Text>Pause</Typography.Text>
                      </Flex>
                    ) : (
                      <Flex align="center" gap={4}>
                        <div className="pulse" />
                        <Typography.Text>Listen</Typography.Text>
                      </Flex>
                    )}
                  </Button>
                </Tooltip>
                <Options actions={actions} details={channel}>
                  <Button
                    className="option hoverable recording-options"
                    size="small"
                    type="text"
                  >
                    <EllipsisOutlined />
                  </Button>
                </Options>
              </div>
            </div>
          );
        })}
      </div>
      <div className="footer mt-auto">
        <div className="item">
          <Typography.Text className="left-column" type="danger">
            This tool is for testing only. Not for daily use.
          </Typography.Text>
          <Typography.Link
            className="right-column"
            href="https://liveatc.net"
            target="_blank"
          >
            LiveATC.net
          </Typography.Link>
        </div>
      </div>
    </div>
  );
}

export default TranscriptionList;
