/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { IBasicRecord } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const rfd = createReducer(initialState.rfd, (builder) => {
  /** Group Reducer */
  builder
    .addCase(
      createAction(types.ARCHIVE_RFD_GROUP_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: redux.removeMatchCase(state.group, action, "group"),
      })
    )
    .addCase(
      createAction(types.CREATE_RFD_GROUP_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: redux.addResources(state.group, action, "group"),
      })
    )
    .addCase(
      createAction(types.DELETE_RFD_GROUP_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: redux.removeMatchCase(state.group, action, "group"),
      })
    )
    .addCase(
      createAction(types.GET_RFD_GROUP_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: { ...state.group, details: action.group },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_GROUPS_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: redux.joinOrOverwrite(state.group, action),
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_RFD_GROUP),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: { ...state.group, details: action.group },
      })
    )
    .addCase(
      createAction(types.UPDATE_RFD_GROUP_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        group: redux.updateMatchCase(state.group, action, "group"),
      })
    );

  /** Recall Reducer */
  builder
    .addCase(
      createAction(types.ARCHIVE_RFD_RECALL_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: redux.removeMatchCase(state.recall, action, "recall"),
      })
    )
    .addCase(
      createAction(types.CREATE_RFD_RECALL_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: redux.addResources(state.recall, action, "recall"),
      })
    )
    .addCase(
      createAction(types.DELETE_RFD_RECALL_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: redux.removeMatchCase(state.recall, action, "recall"),
      })
    )
    .addCase(
      createAction(types.GET_RFD_RECALL_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: { ...state.recall, details: action.recall },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_RECALLS_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: redux.joinOrOverwrite(state.recall, action),
      })
    )
    .addCase(
      createAction(types.SEND_RFD_RECALL_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: { ...state.recall, details: action.recall },
      })
    )
    .addCase(
      createAction(types.SEND_RFD_RESPONSE_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: { ...state.recall, response: action.response },
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_RFD_RECALL),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: { ...state.recall, details: action.recall },
      })
    )
    .addCase(
      createAction(types.UPDATE_RFD_RECALL_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        recall: redux.updateMatchCase(state.recall, action, "recall"),
      })
    );

  /** Requirement Reducer */
  builder
    .addCase(
      createAction(types.ARCHIVE_RFD_REQUIREMENT_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: redux.removeMatchCase(
          state.requirement,
          action,
          "requirement"
        ),
      })
    )
    .addCase(
      createAction(types.CREATE_RFD_REQUIREMENT_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: redux.addResources(
          state.requirement,
          action,
          "requirement"
        ),
      })
    )
    .addCase(
      createAction(types.DELETE_RFD_REQUIREMENT_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: redux.removeMatchCase(
          state.requirement,
          action,
          "requirement"
        ),
      })
    )
    .addCase(
      createAction(types.GET_RFD_REQUIREMENT_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: { ...state.requirement, details: action.requirement },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_REQUIREMENTS_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: redux.joinOrOverwrite(state.requirement, action),
      })
    )
    .addCase(
      createAction(types.SET_ACTIVE_RFD_REQUIREMENT),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: { ...state.requirement, details: action.requirement },
      })
    )
    .addCase(
      createAction(types.CREATE_RFD_ROSTER_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: { ...state.requirement, export: action.message },
      })
    )
    .addCase(
      createAction(types.TOGGLE_RFD_REQUIREMENTS_MODAL),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: { ...state.requirement, details: action?.details || {} },
      })
    )
    .addCase(
      createAction(types.TOGGLE_RFD_REQUIREMENT_VERIFICATION_MODAL),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: { ...state.requirement, details: action?.details || {} },
      })
    )
    .addCase(
      createAction(types.UPDATE_RFD_REQUIREMENT_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        requirement: redux.updateMatchCase(
          state.requirement,
          action,
          "requirement"
        ),
      })
    );

  /** Requirement Metrics */
  builder
    .addCase(
      createAction(types.SEARCH_RFD_METRICS_RECALL_OVERVIEW_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: {
          ...state.metrics,
          recall: { ...state.metrics.recall, details: action.recall },
        },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_METRICS_RECALL_ROLLUP_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: { ...state.metrics, rollup: action.rollup },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_METRICS_GROUPS_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: {
          ...state.metrics,
          groups: redux.joinOrOverwrite(
            { details: {} as IBasicRecord, list: state.metrics.groups },
            action
          )["list"],
        },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_METRICS_RESPONSES_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: {
          ...state.metrics,
          responses: redux.joinOrOverwrite(
            { details: {} as IBasicRecord, list: state.metrics.responses },
            action
          )["list"],
        },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_METRICS_REQUIREMENT_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: {
          ...state.metrics,
          requirement: {
            ...state.metrics.requirement,
            details: action.requirement,
          },
        },
      })
    )
    .addCase(
      createAction(types.SEARCH_RFD_METRICS_RESPONSE_PARTICIPANTS_SUCCESS),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: {
          ...state.metrics,
          participants: redux.joinOrOverwrite(
            { details: {} as IBasicRecord, list: state.metrics.participants },
            action
          )["list"],
        },
      })
    )
    .addCase(
      createAction(types.TOGGLE_RFD_METRICS_GROUP_CARD),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: { ...state.metrics, active: action.details.resource_name },
      })
    )
    .addCase(
      createAction(types.TOGGLE_RFD_METRICS_REQUIREMENT_CARD),
      (state: IRootState["rfd"], action: AnyAction) => ({
        ...state,
        metrics: { ...state.metrics, active: action.details.resource_name },
      })
    )
    .addDefaultCase((state) => state);
});

export default rfd;
