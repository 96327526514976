import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { IBroadcast } from "types";
import type { AnyAction, IRootState } from "../configureStore";

const broadcast = createReducer(initialState.broadcast, (builder) => {
  /** Broadcast Details and List */
  builder
    .addCase(
      createAction(types.CREATE_BROADCAST_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return redux.addResources<IBroadcast>(state, action, "broadcast");
      }
    )
    .addCase(
      createAction(types.DELETE_BROADCAST_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return {
          ...redux.removeMatchCase(state, action, "broadcast"),
          metrics: {
            broadcast: {},
            mediums: [],
            total: { sent: 0, unsent: 0 },
          },
          response: { details: {}, list: [] },
        };
      }
    )
    .addCase(
      createAction(types.GET_BROADCAST_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return { ...state, details: action.broadcast };
      }
    )
    .addCase(
      createAction(types.GET_BROADCAST_METRICS_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return { ...state, metrics: action.metrics };
      }
    )
    .addCase(
      createAction(types.SEARCH_BROADCAST_RESPONSES_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return {
          ...state,
          response: redux.joinOrOverwrite(state.response, action),
        };
      }
    )
    .addCase(
      createAction(types.SEARCH_BROADCASTS_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return redux.joinOrOverwrite<IBroadcast>(state, action);
      }
    )
    .addCase(
      createAction(types.SEND_BROADCAST_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return {
          ...state,
          ...redux.updateMatchCase(state, action, "broadcast"),
        };
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_BROADCAST_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => ({
        ...state,
        details: action.broadcast,
      })
    )
    .addCase(
      createAction(types.TOGGLE_BROADCAST_MODAL),
      (state: IRootState["broadcast"], action: AnyAction) => ({
        ...state,
        details: action.details,
      })
    )
    .addCase(
      createAction(types.UPDATE_BROADCAST_SUCCESS),
      (state: IRootState["broadcast"], action: AnyAction) => {
        return redux.updateMatchCase(state, action, "broadcast");
      }
    )
    .addDefaultCase((state) => state);
});

export default broadcast;
