import React from "react";

/** Recording Tools */
import RecordingOverview from "../components/recording/views/Overview";

const RecordingRoutes = [
  {
    element: (
      <RecordingOverview
        helmet={{ name: "Recordings | Overview", title: "Recordings - IMS" }}
      />
    ),
    key: "ims-recording-route-0",
    path: "apps/recording",
  },
];

export default RecordingRoutes;
