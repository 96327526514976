/* AWS Middleware Actions */
export const AUTH_STATUS_CHANGE = "AUTH_STATUS_CHANGE";
export const GET_ACCESS_SUCCESS = "GET_ACCESS_SUCCESS";

/* Network Middleware */
export const START_NETWORK_MONITORING = "START_NETWORK_MONITORING";
export const STOP_NETWORK_MONITORING = "STOP_NETWORK_MONITORING";
export const UPDATE_NETWORK_STATUS = "UPDATE_NETWORK_STATUS";

/** Device */
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";

/* Socket Middleware Action */
export const ESTABLISH_SOCKET_CONNECTION = "ESTABLISH_SOCKET_CONNECTION";
export const SET_AUTH_CONSTRUCTOR = "SET_AUTH_CONSTRUCTOR";
export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED";
export const SOCKET_RECONNECT = "SOCKET_RECONNECT";
export const SOCKET_SEND_MESSAGE = "SOCKET_SEND_MESSAGE";

/* File Actions */
export const CREATE_FILE_SUCCESS = "CREATE_FILE_SUCCESS";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const RESET_FILE_FILTER_SUCCESS = "RESET_FILE_FILTER_SUCCESS";
export const RESET_FILES_SUCCESS = "RESET_FILES_SUCCESS";
export const SEARCH_FILES_SUCCESS = "SEARCH_FILES_SUCCESS";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";

/* Metadata Actions */
export const CREATE_METADATA_SUCCESS = "CREATE_METADATA_SUCCESS";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const DELETE_METADATA_SUCCESS = "DELETE_METADATA_SUCCESS";
export const RESET_METADATA_SUCCESS = "RESET_METADATA_SUCCESS";
export const UPDATE_METADATA_SUCCESS = "UPDATE_METADATA_SUCCESS";

/** Search Metadata Types */
export const SEARCH_BASES_SUCCESS = "SEARCH_BASES_SUCCESS";
export const SEARCH_BUILDINGS_SUCCESS = "SEARCH_BUILDINGS_SUCCESS";
export const SEARCH_BILLETS_SUCCESS = "SEARCH_BILLETS_SUCCESS";
export const SEARCH_DEFINITIONS_SUCCESS = "SEARCH_DEFINITIONS_SUCCESS";
export const SEARCH_EXERCISES_SUCCESS = "SEARCH_EXERCISES_SUCCESS";
export const SEARCH_MEMBERS_METADATA_SUCCESS =
  "SEARCH_MEMBERS_METADATA_SUCCESS";
export const SEARCH_METADATA_SUCCESS = "SEARCH_METADATA_SUCCESS";
export const SEARCH_MOSS_SUCCESS = "SEARCH_MOSS_SUCCESS";
export const SEARCH_ORGANIZATIONS_SUCCESS = "SEARCH_ORGANIZATIONS_SUCCESS";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_RANKS_SUCCESS = "SEARCH_RANKS_SUCCESS";
export const SEARCH_ROOMS_SUCCESS = "SEARCH_ROOMS_SUCCESS";
export const SEARCH_SECTIONS_SUCCESS = "SEARCH_SECTIONS_SUCCESS";

/* SET Constructors */
export const SET_ACTIVE_BROADCAST_SUCCESS = "SET_ACTIVE_BROADCAST_SUCCESS";
export const SET_ACTIVE_CHANNEL_SUCCESS = "SET_ACTIVE_CHANNEL_SUCCESS";
export const SET_ACTIVE_CHAT_VIEW = "SET_ACTIVE_CHAT_VIEW";
export const SET_ACTIVE_CHECKIN_SUCCESS = "SET_ACTIVE_CHECKIN_SUCCESS";
export const SET_ACTIVE_CHECKIN_STEP_SUCCESS =
  "SET_ACTIVE_CHECKIN_STEP_SUCCESS";
export const SET_ACTIVE_LICENSE_SUCCESS = "SET_ACTIVE_LICENSE_SUCCESS";
export const SET_ACTIVE_MEMBER_SUCCESS = "SET_ACTIVE_MEMBER_SUCCESS";
export const SET_ACTIVE_MESSAGE = "SET_ACTIVE_MESSAGE";
export const SET_ACTIVE_NOTIFICATION_SUCCESS =
  "SET_ACTIVE_NOTIFICATION_SUCCESS";
export const SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS =
  "SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS";
export const SET_ACTIVE_METADATA_SUCCESS = "SET_ACTIVE_METADATA_SUCCESS";
export const SET_ACTIVE_RFD_GROUP = "SET_ACTIVE_RFD_GROUP";
export const SET_ACTIVE_RFD_METRICS_GROUP = "SET_ACTIVE_RFD_METRICS_GROUP";
export const SET_ACTIVE_RFD_RECALL = "SET_ACTIVE_RFD_RECALL";
export const SET_ACTIVE_RFD_REQUIREMENT = "SET_ACTIVE_RFD_REQUIREMENT";
export const SET_ACTIVE_SURVEY_SUCCESS = "SET_ACTIVE_SURVEY_SUCCESS";
export const SET_ACTIVE_SURVEY_FORM_SUCCESS = "SET_ACTIVE_SURVEY_FORM_SUCCESS";
export const SET_ACTIVE_TICKET_SUCCESS = "SET_ACTIVE_TICKET_SUCCESS";
export const SET_ACTIVE_TICKET_CATEGORY_SUCCESS =
  "SET_ACTIVE_TICKET_CATEGORY_SUCCESS";
export const SET_ACTIVE_TICKET_SUBCATEGORY_SUCCESS =
  "SET_ACTIVE_TICKET_SUBCATEGORY_SUCCESS";

/* All Toggle Actions. Modals, Sidebars, etc */
export const TOGGLE_AAR_MODAL = "TOGGLE_AAR_MODAL";
export const TOGGLE_DUTY_MODAL = "TOGGLE_DUTY_MODAL";
export const TOGGLE_BARRACKS_MAINTENANCE_MODAL =
  "TOGGLE_BARRACKS_MAINTENANCE_MODAL";
export const TOGGLE_BROADCAST_MODAL = "TOGGLE_BROADCAST_MODAL";
export const TOGGLE_CHANNEL_MODAL = "TOGGLE_CHANNEL_MODAL";
export const TOGGLE_CHANNEL_SIDEBAR = "TOGGLE_CHANNEL_SIDEBAR";
export const TOGGLE_CHECKIN_MODAL = "TOGGLE_CHECKIN_MODAL";
export const TOGGLE_CHECKIN_STEP_MODAL = "TOGGLE_CHECKIN_STEP_MODAL";
export const TOGGLE_CONTACT_MODAL = "TOGGLE_CONTACT_MODAL";
export const TOGGLE_CONTACT_SIDEBAR = "TOGGLE_CONTACT_SIDEBAR";
export const TOGGLE_FILTER_MODAL = "TOGGLE_FILTER_MODAL";
export const TOGGLE_INVITATION_MODAL = "TOGGLE_INVITATION_MODAL";
export const TOGGLE_LEFT_SIDEBAR = "TOGGLE_LEFT_SIDEBAR";
export const TOGGLE_LICENSE_MODAL = "TOGGLE_LICENSE_MODAL";
export const TOGGLE_LICENSE_REQUEST_MODAL = "TOGGLE_LICENSE_REQUEST_MODAL";
export const TOGGLE_METADATA_MODAL = "TOGGLE_METADATA_MODAL";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";
export const TOGGLE_NOTIFICATION_TEMPLATE_MODAL =
  "TOGGLE_NOTIFICATION_TEMPLATE_MODAL";
export const TOGGLE_OPTIONS_MENU = "TOGGLE_OPTIONS_MENU";
export const TOGGLE_SIDEBAR_MENU = "TOGGLE_SIDEBAR_MENU";
export const TOGGLE_REQUEST_LICENSE_MODAL = "TOGGLE_REQUEST_LICENSE_MODAL";
export const TOGGLE_RFD_METRICS_GROUP_CARD = "TOGGLE_RFD_METRICS_GROUP_CARD";
export const TOGGLE_RFD_METRICS_REQUIREMENT_CARD =
  "TOGGLE_RFD_METRICS_REQUIREMENT_CARD";
export const TOGGLE_RFD_GROUP_MODAL = "TOGGLE_RFD_GROUP_MODAL";
export const TOGGLE_RFD_RECALL_MODAL = "TOGGLE_RFD_RECALL_MODAL";
export const TOGGLE_RFD_RECALL_RESPONSE_MODAL =
  "TOGGLE_RFD_RECALL_RESPONSE_MODAL";
export const TOGGLE_RFD_RECALL_ROLLUP_MODAL = "TOGGLE_RFD_RECALL_ROLLUP_MODAL";
export const TOGGLE_RFD_REQUIREMENTS_MODAL = "TOGGLE_RFD_REQUIREMENTS_MODAL";
export const TOGGLE_RFD_REQUIREMENT_VERIFICATION_MODAL =
  "TOGGLE_RFD_REQUIREMENT_VERIFICATION_MODAL";
export const TOGGLE_RFD_ROSTER_MODAL = "TOGGLE_RFD_ROSTER_MODAL";
export const TOGGLE_SURVEY_MODAL = "TOGGLE_SURVEY_MODAL";
export const TOGGLE_TICKET_MODAL = "TOGGLE_TICKET_MODAL";
export const TOGGLE_TICKET_CATEGORY_MODAL = "TOGGLE_TICKET_CATEGORY_MODAL";
export const TOGGLE_TICKET_SUBCATEGORY_MODAL =
  "TOGGLE_TICKET_SUBCATEGORY_MODAL";
export const TOGGLE_TOUR_SUCCESS = "TOGGLE_TOUR_SUCCESS";
export const TOGGLE_TRANSCRIBE_MODAL = "TOGGLE_TRANSCRIBE_MODAL";

/* --- Tool Actions Below This Line --- */

/* After Actions Report */
export const APPROVE_AAR_SUCCESS = "APPROVE_AAR_SUCCESS";
export const ARCHIVE_AAR_SUCCESS = "ARCHIVE_AAR_SUCCESS";
export const CREATE_AAR_SUCCESS = "CREATE_AAR_SUCCESS";
export const DELETE_AAR_SUCCESS = "DELETE_AAR_SUCCESS";
export const FILTER_AARS_SUCCESS = "FILTER_AARS_SUCCESS";
export const GET_AAR_SUCCESS = "GET_AAR_SUCCESS";
export const GET_AAR_DEFAULTS = "GET_AAR_DEFAULTS";
export const GET_AARS_SUCCESS = "GET_AARS_SUCCESS";
export const REVISE_AAR_SUCCESS = "REVISE_AAR_SUCCESS";
export const RESET_AARS_SUCCESS = "RESET_AARS_SUCCESS";
export const ROUTE_AAR_SUCCESS = "ROUTE_AAR_SUCCESS";
export const SEARCH_AARS_SUCCESS = "SEARCH_AARS_SUCCESS";
export const TOGGLE_AAR_DETAILS_SUCCESS = "TOGGLE_AAR_DETAILS_SUCCESS";
export const UPDATE_AAR_SUCCESS = "UPDATE_AAR_SUCCESS";

/* Barracks Actions */
export const CREATE_ROOM_ACTION = "CREATE_ROOM_ACTION";
export const DELETE_ROOM_ACTION = "DELETE_ROOM_ACTION";
export const GET_ROOM_ACTION = "GET_ROOM_ACTION";
export const GET_ROOMS_ACTION = "GET_ROOMS_ACTION";
export const RESET_ROOMS_ACTION = "RESET_ROOMS_ACTION";
export const SEARCH_ROOMS_ACTION = "SEARCH_ROOMS_ACTION";
export const UPDATE_ROOM_ACTION = "UPDATE_ROOM_ACTION";

/* Barracks Duty Actions */
export const CREATE_DUTY_ACTION = "CREATE_DUTY_ACTION";
export const DELETE_DUTY_ACTION = "DELETE_DUTY_ACTION";
export const GET_DUTY_ACTION = "GET_DUTY_ACTION";
export const SEARCH_DUTY_ACTION = "SEARCH_DUTY_ACTION";
export const UPDATE_DUTY_ACTION = "UPDATE_DUTY_ACTION";

/* Barracks Metrics */
export const GET_BARRACKS_DUTY_METRICS_ACTION =
  "GET_BARRACKS_DUTY_METRICS_ACTION";
export const GET_BARRACKS_REQUEST_METRICS_ACTION =
  "GET_BARRACKS_REQUEST_METRICS_ACTION";
export const GET_BARRACKS_ROOM_METRICS_ACTION =
  "GET_BARRACKS_ROOM_METRICS_ACTION";

/* Broadcast Actions */
export const CREATE_BROADCAST_SUCCESS = "CREATE_BROADCAST_SUCCESS";
export const DELETE_BROADCAST_SUCCESS = "DELETE_BROADCAST_SUCCESS";
export const GET_BROADCAST_SUCCESS = "GET_BROADCAST_SUCCESS";
export const GET_BROADCAST_METRICS_SUCCESS = "GET_BROADCAST_METRICS_SUCCESS";
export const RESET_BROADCAST_SUCCESS = "RESET_BROADCAST_SUCCESS";
export const SEARCH_BROADCAST_RESPONSES_SUCCESS =
  "SEARCH_BROADCAST_RESPONSES_SUCCESS";
export const SEARCH_BROADCASTS_SUCCESS = "SEARCH_BROADCASTS_SUCCESS";
export const SEND_BROADCAST_SUCCESS = "SEND_BROADCAST_SUCCESS";
export const UPDATE_BROADCAST_SUCCESS = "UPDATE_BROADCAST_SUCCESS";

/** Chat Channel Actions */
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const SEARCH_CHANNELS_SUCCESS = "SEARCH_CHANNELS_SUCCESS";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";

/* Checkin Actions */
export const ARCHIVE_CHECKIN_SUCCESS = "ARCHIVE_CHECKIN_SUCCESS";
export const CREATE_CHECKIN_SUCCESS = "CREATE_CHECKIN_SUCCESS";
export const DELETE_CHECKIN_SUCCESS = "DELETE_CHECKIN_SUCCESS";
export const GET_CHECKIN_SUCCESS = "GET_CHECKIN_SUCCESS";
export const GET_CHECKINS_SUCCESS = "GET_CHECKINS_SUCCESS";
export const SEARCH_CHECKINS_SUCCESS = "SEARCH_CHECKINS_SUCCESS";
export const UPDATE_CHECKIN_SUCCESS = "UPDATE_CHECKIN_SUCCESS";

export const ARCHIVE_CHECKIN_STEP_SUCCESS = "ARCHIVE_CHECKIN_STEP_SUCCESS";
export const CREATE_CHECKIN_STEP_SUCCESS = "CREATE_CHECKIN_STEP_SUCCESS";
export const DELETE_CHECKIN_STEP_SUCCESS = "DELETE_CHECKIN_STEP_SUCCESS";
export const GET_CHECKIN_STEP_SUCCESS = "GET_CHECKIN_STEP_SUCCESS";
export const GET_CHECKIN_STEPS_SUCCESS = "GET_CHECKIN_STEPS_SUCCESS";
export const SEARCH_CHECKIN_STEPS_SUCCESS = "SEARCH_CHECKIN_STEPS_SUCCESS";
export const UPDATE_CHECKIN_STEP_SUCCESS = "UPDATE_CHECKIN_STEP_SUCCESS";

/* Contact Actions */
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const INVITE_CONTACTS_SUCCESS = "INVITE_CONTACTS_SUCCESS";
export const RESET_CONTACTS_SUCCESS = "RESET_CONTACTS_SUCCESS";
export const SET_ACTIVE_CONTACT_SUCCESS = "SET_ACTIVE_CONTACT_SUCCESS";
export const SEARCH_CONTACTS_SUCCESS = "SEARCH_CONTACTS_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";

/* License actions. CRUD */
export const ASSIGN_LICENSE_SUCCESS = "ASSIGN_LICENSE_SUCCESS";
export const CREATE_LICENSE_SUCCESS = "CREATE_LICENSE_SUCCESS";
export const DELETE_LICENSE_SUCCESS = "DELETE_LICENSE_SUCCESS";
export const GET_LICENSE_SUCCESS = "GET_LICENSE_SUCCESS";
export const GET_LICENSE_METRICS_SUCCESS = "GET_LICENSE_METRICS_SUCCESS";
export const GET_LICENSE_ASSIGNED_TO_SUCCESS =
  "GET_LICENSE_ASSIGNED_TO_SUCCESS";
export const GET_LICENSES_SUCCESS = "GET_LICENSES_SUCCESS";
export const REASSIGN_LICENSE_SUCCESS = "REASSIGN_LICENSE_SUCCESS";
export const REMOVE_LICENSE_SUCCESS = "REMOVE_LICENSE_SUCCESS";
export const RESET_LICENSES_SUCCESS = "RESET_LICENSES_SUCCESS";
export const SEARCH_LICENSES_SUCCESS = "SEARCH_LICENSES_SUCCESS";
export const UPDATE_LICENSE_SUCCESS = "UPDATE_LICENSE_SUCCESS";

/* Member Actions */
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const INVITE_MEMBERS_SUCCESS = "INVITE_MEMBERS_SUCCESS";
export const RESET_MEMBERS_SUCCESS = "RESET_MEMBERS_SUCCESS";
export const SEARCH_MEMBERS_SUCCESS = "SEARCH_MEMBERS_SUCCESS";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";

/* Member Preference Actions */
export const CREATE_PREFERENCE_SUCCESS = "CREATE_PREFERENCE_SUCCESS";
export const DELETE_PREFERENCE_SUCCESS = "DELETE_PREFERENCE_SUCCESS";
export const GET_PREFERENCE_SUCCESS = "GET_PREFERENCE_SUCCESS";
export const SEARCH_PREFERENCES_SUCCESS = "SEARCH_PREFERENCES_SUCCESS";
export const SET_ACTIVE_PREFERENCE_SUCCESS = "SET_ACTIVE_PREFERENCE_SUCCESS";
export const UPDATE_PREFERENCE_SUCCESS = "UPDATE_PREFERENCE_SUCCESS";

/* Navbar Search Actions */
export const RESET_SEARCH_SUCCESS = "RESET_SEARCH_SUCCESS";
export const SEARCH_HIERARCHY_SUCCESS = "SEARCH_HIERARCHY_SUCCESS";
export const UPDATE_SEARCH_SUCCESS = "UPDATE_SEARCH_SUCCESS";
export const UPDATE_SEARCH_PLACEHOLDER_SUCCESS =
  "UPDATE_SEARCH_PLACEHOLDER_SUCCESS";

/* Notification Actions */
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const RESET_NOTIFICATIONS_SUCCESS = "RESET_NOTIFICATIONS_SUCCESS";
export const SEARCH_NOTIFICATIONS_SUCCESS = "SEARCH_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

/** Notification Template Actions */
export const CREATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "CREATE_NOTIFICATION_TEMPLATE_SUCCESS";
export const DELETE_NOTIFICATION_TEMPLATE_SUCCESS =
  "DELETE_NOTIFICATION_TEMPLATE_SUCCESS";
export const GET_NOTIFICATION_TEMPLATE_SUCCESS =
  "GET_NOTIFICATION_TEMPLATE_SUCCESS";
export const SEARCH_NOTIFICATION_TEMPLATES_SUCCESS =
  "SEARCH_NOTIFICATION_TEMPLATES_SUCCESS";
export const RESET_NOTIFICATION_TEMPLATES_SUCCESS =
  "RESET_NOTIFICATION_TEMPLATES_SUCCESS";
export const UPDATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "UPDATE_NOTIFICATION_TEMPLATE_SUCCESS";

/** Notification Responses */
export const CREATE_NOTIFICATION_RESPONSE_SUCCESS =
  "CREATE_NOTIFICATION_RESPONSE_SUCCESS";
export const SEARCH_NOTIFICATION_RESPONSES_SUCCESS =
  "SEARCH_NOTIFICATION_RESPONSES_SUCCESS";

/* RFD Group Actions */
export const ARCHIVE_RFD_GROUP_SUCCESS = "ARCHIVE_RFD_GROUP_SUCCESS";
export const CREATE_RFD_GROUP_SUCCESS = "CREATE_RFD_GROUP_SUCCESS";
export const CREATE_RFD_ROSTER_SUCCESS = "CREATE_RFD_ROSTER_SUCCESS";
export const DELETE_RFD_GROUP_SUCCESS = "DELETE_RFD_GROUP_SUCCESS";
export const FILTER_RFD_GROUPS_SUCCESS = "FILTER_RFD_GROUPS_SUCCESS";
export const FILTER_RFD_PARTICIPANTS_SUCCESS =
  "FILTER_RFD_PARTICIPANTS_SUCCESS";
export const GET_RFD_GROUP_SUCCESS = "GET_RFD_GROUP_SUCCESS";
export const SEARCH_RFD_GROUPS_SUCCESS = "SEARCH_RFD_GROUPS_SUCCESS";
export const UPDATE_RFD_GROUP_SUCCESS = "UPDATE_RFD_GROUP_SUCCESS";

/* RFD Requirement Actions */
export const ARCHIVE_RFD_REQUIREMENT_SUCCESS =
  "ARCHIVE_RFD_REQUIREMENT_SUCCESS";
export const CREATE_RFD_REQUIREMENT_SUCCESS = "CREATE_RFD_REQUIREMENT_SUCCESS";
export const DELETE_RFD_REQUIREMENT_SUCCESS = "DELETE_RFD_REQUIREMENT_SUCCESS";
export const FILTER_RFD_REQUIREMENTS_SUCCESS =
  "FILTER_RFD_REQUIREMENTS_SUCCESS";
export const GET_RFD_REQUIREMENT_SUCCESS = "GET_RFD_REQUIREMENT_SUCCESS";
export const GET_RFD_REQUIREMENTS_SUCCESS = "GET_RFD_REQUIREMENTS_SUCCESS";
export const SEARCH_RFD_USERS_SUCCESS = "SEARCH_RFD_USERS_SUCCESS";
export const UPDATE_RFD_REQUIREMENT_SUCCESS = "UPDATE_RFD_REQUIREMENT_SUCCESS";

/* RFD Recall Actions */
export const ARCHIVE_RFD_RECALL_SUCCESS = "ARCHIVE_RFD_RECALL_SUCCESS";
export const CREATE_RFD_RECALL_SUCCESS = "CREATE_RFD_RECALL_SUCCESS";
export const DELETE_RFD_RECALL_SUCCESS = "DELETE_RFD_RECALL_SUCCESS";
export const FILTER_RFD_RECALLS_SUCCESS = "FILTER_RFD_RECALLS_SUCCESS";
export const GET_RFD_RECALL_SUCCESS = "GET_RFD_RECALL_SUCCESS";
export const SEARCH_RFD_RECALLS_SUCCESS = "SEARCH_RFD_RECALLS_SUCCESS";
export const SEND_RFD_RECALL_SUCCESS = "SEND_RFD_RECALL_SUCCESS";
export const UPDATE_RFD_RECALL_SUCCESS = "UPDATE_RFD_RECALL_SUCCESS";
export const SEND_RFD_RESPONSE_SUCCESS = "SEND_RFD_RESPONSE_SUCCESS";

/* RFD Metric Actions */
export const SEARCH_RFD_METRICS_GROUPS_SUCCESS =
  "SEARCH_RFD_METRICS_GROUPS_SUCCESS";
export const SEARCH_RFD_METRICS_RECALL_OVERVIEW_SUCCESS =
  "SEARCH_RFD_METRICS_RECALL_OVERVIEW_SUCCESS";
export const SEARCH_RFD_METRICS_RECALL_ROLLUP_SUCCESS =
  "SEARCH_RFD_METRICS_RECALL_ROLLUP_SUCCESS";
export const SEARCH_RFD_METRICS_RESPONSES_SUCCESS =
  "SEARCH_RFD_METRICS_RESPONSES_SUCCESS";
export const SEARCH_RFD_METRICS_RESPONSE_PARTICIPANTS_SUCCESS =
  "SEARCH_RFD_METRICS_RESPONSE_PARTICIPANTS_SUCCESS";
export const SEARCH_RFD_METRICS_REQUIREMENT_SUCCESS =
  "SEARCH_RFD_METRICS_REQUIREMENT_SUCCESS";
export const SEARCH_RFD_REQUIREMENTS_METRICS_PARTICIPANTS_SUCCESS =
  "SEARCH_RFD_REQUIREMENTS_METRICS_PARTICIPANTS_SUCCESS";
export const SEARCH_RFD_REQUIREMENTS_SUCCESS =
  "SEARCH_RFD_REQUIREMENTS_SUCCESS";

/** Recording Actions */
export const CREATE_RECORDING_SUCCESS = "CREATE_RECORDING_SUCCESS";
export const DELETE_RECORDING_SUCCESS = "DELETE_RECORDING_SUCCESS";
export const GET_RECORDING_SUCCESS = "GET_RECORDING_SUCCESS";
export const SEARCH_RECORDINGS_SUCCESS = "SEARCH_RECORDINGS_SUCCESS";
export const SET_ACTIVE_RECORDING_SUCCESS = "SET_ACTIVE_RECORDING_SUCCESS";
export const UPDATE_RECORDING_SUCCESS = "UPDATE_RECORDING_SUCCESS";

/* Trouble Ticket Actions */
/* Trouble Ticket Categories */
export const CREATE_TICKET_CATEGORY_SUCCESS = "CREATE_TICKET_CATEGORY_SUCCESS";
export const DELETE_TICKET_CATEGORY_SUCCESS = "DELETE_TICKET_CATEGORY_SUCCESS";
export const GET_TICKET_CATEGORY_SUCCESS = "GET_TICKET_CATEGORY_SUCCESS";
export const SEARCH_TICKET_CATEGORIES_SUCCESS =
  "SEARCH_TICKET_CATEGORIES_SUCCESS";
export const UPDATE_TICKET_CATEGORY_SUCCESS = "UPDATE_TICKET_CATEGORY_SUCCESS";

export const ARCHIVE_TICKET_SUCCESS = "ARCHIVE_TICKET_SUCCESS";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const GET_TICKET_METRICS_SUCCESS = "GET_TICKET_METRICS_SUCCESS";
export const GET_TICKET_METRICS = "GET_TICKET_METRICS";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const SEARCH_TICKETS_SUCCESS = "SEARCH_TICKETS_SUCCESS";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";

/* Trouble Ticket History Actions */
export const CREATE_TICKET_HISTORY_SUCCESS = "CREATE_TICKET_HISTORY_SUCCESS";
export const RESET_TICKET_HISTORY_SUCCESS = "RESET_TICKET_HISTORY_SUCCESS";
export const SEARCH_TICKET_HISTORY_SUCCESS = "SEARCH_TICKET_HISTORY_SUCCESS";

/* Trouble Ticket Subcategories */
export const CREATE_TICKET_SUBCATEGORY_SUCCESS =
  "CREATE_TICKET_SUBCATEGORY_SUCCESS";
export const DELETE_TICKET_SUBCATEGORY_SUCCESS =
  "DELETE_TICKET_SUBCATEGORY_SUCCESS";
export const GET_TICKET_SUBCATEGORY_SUCCESS = "GET_TICKET_SUBCATEGORY_SUCCESS";
export const RESET_TICKET_SUBCATEGORIES_SUCCESS =
  "RESET_TICKET_SUBCATEGORIES_SUCCESS";
export const SEARCH_TICKET_SUBCATEGORIES_SUCCESS =
  "SEARCH_TICKET_SUBCATEGORIES_SUCCESS";
export const UPDATE_TICKET_SUBCATEGORY_SUCCESS =
  "UPDATE_TICKET_SUBCATEGORY_SUCCESS";

/* Notifications SNS / Subscriptions */
export const ADD_PUSH_SUBSCRIPTION_SUCCESS = "ADD_PUSH_SUBSCRIPTION_SUCCESS";
export const REMOVE_PUSH_SUBSCRIPTION_SUCCESS =
  "REMOVE_PUSH_SUBSCRIPTION_SUCCESS";

/* SmartPack */
export const GET_SMARTPACK_CATEGORIES_SUCCESS =
  "GET_SMARTPACK_CATEGORIES_SUCCESS";
export const GET_SMARTPACK_PAGES_SUCCESS = "GET_SMARTPACK_PAGES_SUCCESS";
export const CREATE_SMARTPACK_CATEGORY_SUCCESS =
  "CREATE_SMARTPACK_CATEGORY_SUCCESS";
export const CREATE_SMARTPACK_PAGE_SUCCESS = "CREATE_SMARTPACK_PAGE_SUCCESS";
export const DELETE_SMARTPACK_CATEGORY_SUCCESS =
  "DELETE_SMARTPACK_CATEGORY_SUCCESS";
export const DELETE_SMARTPACK_PAGE_SUCCESS = "DELETE_SMARTPACK_PAGE_SUCCESS";
export const SAVE_SMARTPACK_PAGE_SUCCESS = "SAVE_SMARTPACK_PAGE_SUCCESS";

/* Survey Actions */
export const ARCHIVE_SURVEY_SUCCESS = "ARCHIVE_SURVEY_SUCCESS";
export const CREATE_SURVEY_SUCCESS = "CREATE_SURVEY_SUCCESS";
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
export const GET_SURVEY_SUCCESS = "GET_SURVEY_SUCCESS";
export const SEARCH_SURVEY_METRICS_SUCCESS = "SEARCH_SURVEY_METRICS_SUCCESS";
export const SEARCH_SURVEYS_SUCCESS = "SEARCH_SURVEYS_SUCCESS";
export const SEND_SURVEY_SUCCESS = "SEND_SURVEY_SUCCESS";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";

/* Survey Form Actions */
export const CREATE_SURVEY_FORM_SUCCESS = "CREATE_SURVEY_FORM_SUCCESS";
export const DELETE_SURVEY_FORM_SUCCESS = "DELETE_SURVEY_FORM_SUCCESS";
export const GET_SURVEY_FORM_SUCCESS = "GET_SURVEY_FORM_SUCCESS";
export const SEARCH_SURVEY_FORMS_SUCCESS = "SEARCH_SURVEY_FORMS_SUCCESS";
export const UPDATE_SURVEY_FORM_SUCCESS = "UPDATE_SURVEY_FORM_SUCCESS";
