/** Redux */
import * as types from "./types";

/** Enums */
import { ToggleResource } from "../../types";

/** Types */
import type { IAppDispatch } from "../configureStore";
import type { IToggleAction } from "../../types";

export default function toggleActions<T>(props: IToggleAction<T>) {
  console.log(props.resource);
  return (dispatch: IAppDispatch) => {
    let type = types.TOGGLE_CONTACT_MODAL;
    if (props.resource === ToggleResource.AARModal)
      type = types.TOGGLE_AAR_MODAL;
    else if (props.resource === ToggleResource.BarracksDutyModal)
      type = types.TOGGLE_DUTY_MODAL;
    else if (props.resource === ToggleResource.BarracksMaintenanceModal)
      type = types.TOGGLE_BARRACKS_MAINTENANCE_MODAL;
    else if (props.resource === ToggleResource.BroadcastModal)
      type = types.TOGGLE_BROADCAST_MODAL;
    else if (props.resource === ToggleResource.ChannelModal)
      type = types.TOGGLE_CHANNEL_MODAL;
    else if (props.resource === ToggleResource.ChannelSidebar)
      type = types.TOGGLE_CHANNEL_SIDEBAR;
    else if (props.resource === ToggleResource.CheckinModal)
      type = types.TOGGLE_CHECKIN_MODAL;
    else if (props.resource === ToggleResource.CheckinStepModal)
      type = types.TOGGLE_CHECKIN_STEP_MODAL;
    else if (props.resource === ToggleResource.ContactModal)
      type = types.TOGGLE_CONTACT_MODAL;
    else if (props.resource === ToggleResource.ContactSidebar)
      type = types.TOGGLE_CONTACT_SIDEBAR;
    else if (props.resource === ToggleResource.FilterModal)
      type = types.TOGGLE_FILTER_MODAL;
    else if (props.resource === ToggleResource.MemberInvite)
      type = types.TOGGLE_INVITATION_MODAL;
    else if (props.resource === ToggleResource.LicenseModal)
      type = types.TOGGLE_LICENSE_MODAL;
    else if (props.resource === ToggleResource.LicenseRequestModal)
      type = types.TOGGLE_LICENSE_REQUEST_MODAL;
    else if (props.resource === ToggleResource.LeftSidebar)
      type = types.TOGGLE_LEFT_SIDEBAR;
    else if (props.resource === ToggleResource.MetadataModal)
      type = types.TOGGLE_METADATA_MODAL;
    else if (props.resource === ToggleResource.NotificationTemplateModal)
      type = types.TOGGLE_NOTIFICATION_TEMPLATE_MODAL;
    else if (props.resource === ToggleResource.RFDGroupModal)
      type = types.TOGGLE_RFD_GROUP_MODAL;
    else if (props.resource === ToggleResource.RFDRecallModal)
      type = types.TOGGLE_RFD_RECALL_MODAL;
    else if (props.resource === ToggleResource.RFDRecallResponseModal)
      type = types.TOGGLE_RFD_RECALL_RESPONSE_MODAL;
    else if (props.resource === ToggleResource.RFDRecallRollupModal)
      type = types.TOGGLE_RFD_RECALL_ROLLUP_MODAL;
    else if (props.resource === ToggleResource.RFDRequirementModal)
      type = types.TOGGLE_RFD_REQUIREMENTS_MODAL;
    else if (props.resource === ToggleResource.RFDRosterModal)
      type = types.TOGGLE_RFD_ROSTER_MODAL;
    else if (props.resource === ToggleResource.RFDRequirementVerifyModal)
      type = types.TOGGLE_RFD_REQUIREMENT_VERIFICATION_MODAL;
    else if (props.resource === ToggleResource.SurveyModal)
      type = types.TOGGLE_SURVEY_MODAL;
    else if (props.resource === ToggleResource.TicketModal)
      type = types.TOGGLE_TICKET_MODAL;
    else if (props.resource === ToggleResource.Tour)
      type = types.TOGGLE_TOUR_SUCCESS;
    else if (props.resource === ToggleResource.TranscribeModal)
      type = types.TOGGLE_TRANSCRIBE_MODAL;

    dispatch({ ...props, type });
  };
}
