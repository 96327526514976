/** Vendors */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "types";

/** Types */
import type { IBarracksDuty } from "types";
import type { AnyAction, IRootState } from "../configureStore";

const duty = createReducer(initialState.barracks.duty, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_DUTY_ACTION),
      (state: IRootState["barracks"]["duty"], action: AnyAction) => {
        return redux.addResources<IBarracksDuty>(
          state,
          action,
          ResourceId.Duty
        );
      }
    )
    .addCase(
      createAction(types.DELETE_DUTY_ACTION),
      (state: IRootState["barracks"]["duty"], action: AnyAction) => {
        return redux.removeMatchCase<IBarracksDuty>(
          state,
          action,
          ResourceId.Duty
        );
      }
    )
    .addCase(
      createAction(types.GET_DUTY_ACTION),
      (state: IRootState["barracks"]["duty"], action: AnyAction) => {
        return { ...state, details: action.duty };
      }
    )
    .addCase(
      createAction(types.TOGGLE_DUTY_MODAL),
      (state: IRootState["barracks"]["duty"], action: AnyAction) => {
        return { ...state, details: action.duty };
      }
    )
    .addCase(
      createAction(types.UPDATE_DUTY_ACTION),
      (state: IRootState["barracks"]["duty"], action: AnyAction) => {
        return redux.updateMatchCase<IBarracksDuty>(
          state,
          action,
          ResourceId.Duty
        );
      }
    )
    .addDefaultCase((state) => state);
});

const metrics = createReducer(initialState.barracks.metrics, (builder) => {
  builder
    .addCase(
      createAction(types.GET_BARRACKS_DUTY_METRICS_ACTION),
      (state: IRootState["barracks"]["metrics"], action: AnyAction) => {
        return { ...state, duty: action.metrics };
      }
    )
    .addCase(
      createAction(types.GET_BARRACKS_REQUEST_METRICS_ACTION),
      (state: IRootState["barracks"]["metrics"], action: AnyAction) => {
        return { ...state, requests: action.metrics };
      }
    )
    .addCase(
      createAction(types.GET_BARRACKS_ROOM_METRICS_ACTION),
      (state: IRootState["barracks"]["metrics"], action: AnyAction) => {
        return { ...state, rooms: action.metrics };
      }
    )
    .addDefaultCase((state) => state);
});

const barracks = combineReducers({
  duty,
  metrics,
});

export default barracks;
