/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";

export function initializeSocketAction() {
  return (dispatch: IAppDispatch) => {
    dispatch({ type: types.ESTABLISH_SOCKET_CONNECTION });
  };
}
