/** Vendors */
import { Button, Tooltip } from "antd";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";

/** Types */
export interface ISyncLoader {
  actions: { onRefresh: () => void };
  loading: boolean;
  title?: string;
}

function SyncLoader({ actions, loading, title = "Refresh Data" }: ISyncLoader) {
  return (
    <Tooltip placement="left" title={title}>
      <Button
        className="option hoverable"
        onClick={actions.onRefresh}
        type="text"
      >
        {loading ? (
          <LoadingOutlined style={{ color: "#aaa", fontSize: 16 }} />
        ) : (
          <SyncOutlined style={{ color: "#aaa", fontSize: 16 }} />
        )}
      </Button>
    </Tooltip>
  );
}

export default SyncLoader;
