/** Redux */
import * as types from "./types";

/** Types */
import type { IAppDispatch } from "../configureStore";
import type { IAnyObject, IRecording, ISearchRecordings } from "../../types";

const sendMessage = (dispatch: IAppDispatch) => {
  return (payload: IAnyObject) => {
    dispatch({ payload, type: types.SOCKET_SEND_MESSAGE });
  };
};

export function createRecordingAction(recording: IRecording) {
  return (dispatch) => {
    sendMessage(dispatch)({
      action: "recording",
      method: "PUT",
      path: "recording",
      recording,
      type: types.CREATE_RECORDING_SUCCESS,
    });
  };
}

export function deleteRecordingAction(recording: IRecording) {
  return (dispatch) => {
    sendMessage(dispatch)({
      action: "recording",
      method: "DELETE",
      path: "recording",
      recording,
      type: types.DELETE_RECORDING_SUCCESS,
    });
  };
}

export function searchRecordingsAction(search: ISearchRecordings) {
  return (dispatch) => {
    sendMessage(dispatch)({
      action: "recording",
      method: "GET",
      path: "recording/search",
      search,
      type: types.SEARCH_RECORDINGS_SUCCESS,
    });
  };
}
