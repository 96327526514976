/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { IFile } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

/** File Actions */
const file = createReducer(initialState.file, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_FILE_SUCCESS),
      (state: IRootState["file"], action: AnyAction) => {
        return redux.addResources<IFile>(state, action, "file", "key");
      }
    )
    .addCase(
      createAction(types.DELETE_FILE_SUCCESS),
      (state: IRootState["file"], action: AnyAction) => {
        return redux.removeMatchCase<IFile>(state, action, "file", "key");
      }
    )
    .addCase(createAction(types.RESET_FILES_SUCCESS), (state) => {
      return { ...state, list: [] };
    })
    .addCase(
      createAction(types.SEARCH_FILES_SUCCESS),
      (state: IRootState["file"], action: AnyAction) => {
        return redux.joinOrOverwrite<IFile>(state, action);
      }
    )
    .addCase(
      createAction(types.UPDATE_FILE_SUCCESS),
      (state: IRootState["file"], action: AnyAction) => {
        return redux.updateMatchCase<IFile>(state, action, "file", "key");
      }
    )
    .addDefaultCase((state) => state);
});

export default file;
