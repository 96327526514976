import React from "react";

/** Vendors */
import { Avatar, Badge, Popover, Tooltip } from "antd";

/** Redux */
import toggleAction from "@redux/actions/toggle";

/** Custom Hooks */
import { useAppDispatch } from "@hooks/useRedux";

/** Custom Methods */
import { formatName, metadata } from "@dist/js/support";

/** Enums */
import { ToggleResource } from "types";

/** Types */
import type { IMember } from "types";
import type { TooltipPlacement } from "antd/lib/tooltip/index";

export interface IFallbackMemberImage {
  enableClick?: boolean;
  enableDetails?: boolean;
  enableTooltip?: boolean;
  index?: number;
  members: IMember[];
  multiple?: boolean;
  placement?: TooltipPlacement;
  width?: number;
}

function FallbackMemberImage({
  enableClick = true,
  enableDetails = true,
  enableTooltip = true,
  index,
  members,
  multiple,
  placement,
  width,
}: IFallbackMemberImage) {
  const dispatch = useAppDispatch();

  const detailedFields = [
    { field: "given_name", label: "Name", method: formatName },
    {
      field: "cellphone",
      label: "Cellphone",
      method: (props: IMember): string => props.cellphone,
    },
    {
      field: "email",
      label: "Email",
      method: (props: IMember): string => props.email,
    },
    {
      field: "nipr_email",
      label: "NIPR Email",
      method: (props: IMember): string => props.nipr_email || "",
    },
    {
      field: "organization",
      label: "Organization",
      method: (props: IMember): string => {
        return metadata.findOrganization(props)?.name || "";
      },
    },
    {
      field: "section",
      label: "Staff Section",
      method: (props: IMember): string => {
        return metadata.findSection(props)?.name || "";
      },
    },
  ];

  const MemberImages = members
    .filter((m, i) => members.indexOf(m) === i)
    .map((props: IMember, i: number) => {
      let icon = (
        <Avatar
          className="pointer"
          key={`avatar-${index}-${props.resource_name}-${new Date().getTime()}`}
          onClick={() =>
            enableClick &&
            dispatch(
              toggleAction({
                details: props,
                resource: ToggleResource.ContactSidebar,
              })
            )
          }
          size={width || 30}
          style={{ backgroundColor: "var(--color4)", fontSize: 14 }}
        >
          {(props.given_name || "NA").substr(0, 1).toUpperCase()}
          {(props.family_name || "AN").substr(0, 1).toUpperCase()}
        </Avatar>
      );

      if (enableTooltip && !enableDetails) {
        icon = (
          <Tooltip
            key={`avatar-tooltip-${index}-${props.resource_name}`}
            placement={placement || "left"}
            title={formatName(props)}
          >
            {icon}
          </Tooltip>
        );
      }

      if (enableTooltip && enableDetails) {
        icon = (
          <Popover
            key={`avatar-${index}-${props.resource_name}`}
            content={
              <div className="avatar-details">
                {detailedFields.reduce(
                  (r: React.ReactElement[], details, j) => {
                    if (props.hasOwnProperty(details.field)) {
                      const text = details.method(props);
                      if (text) {
                        r.push(
                          <div key={`avatar-label-${j}-${props.resource_name}`}>
                            <div className="label">{details.label}</div>
                            <div className="text">{text}</div>
                          </div>
                        );
                      }
                    }
                    return r;
                  },
                  []
                )}
              </div>
            }
            placement={placement || "left"}
          >
            {icon}
          </Popover>
        );
      }

      return !props.socket_connection_active || multiple ? (
        icon
      ) : (
        <Badge dot key={`avatar-${i * 1000}`}>
          {icon}
        </Badge>
      );
    });

  return (
    <Avatar.Group className="mr-auto" maxCount={2}>
      {MemberImages as any}
    </Avatar.Group>
  );
}

export default FallbackMemberImage;
