import { Suspense, lazy, useEffect, useRef, useState } from "react";

/** Vendors */
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Col, Row, Space } from "antd";

/** Custom CSS */
import "../broadcast.css";

/** Types */
import type { IView } from "types";

/** Custom Components */
import BroadcastList from "../cards/broadcast/List";
import LoadingScreen from "../../shared/loading/Screen";
const BroadcastMetrics = lazy(() => import("../cards/metrics/Details"));
const Navigation = lazy(() => import("../shared/Navigation"));
const TemplateList = lazy(() => import("../cards/templates/List"));

function Overview({ helmet }: IView) {
  const [height, setHeight] = useState(window.innerHeight - 140);
  const location = useLocation();
  const boxRef = useRef<HTMLDivElement>(null);

  /** Step 1. Set the height boundaries */
  useEffect(() => {
    const boundingBox = boxRef?.current?.getBoundingClientRect();
    if (boundingBox && !isNaN(boundingBox?.top)) {
      setHeight(window.innerHeight - boundingBox?.top - 160);
    }
  }, [boxRef?.current]);

  return (
    <div className="broadcast">
      <Helmet>
        <title>{helmet.title}</title>
        <link
          rel="canonical"
          href={`${import.meta.env.VITE_REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <Suspense fallback={<LoadingScreen />}>
        <Space className="w-100" direction="vertical">
          <Navigation view="broadcast" />
          <div className="overview" ref={boxRef}>
            <TemplateList />
            <Row gutter={[12, 12]}>
              <Col md={14} sm={16} xs={24}>
                <BroadcastList height={height} />
              </Col>
              <Col md={10} sm={16} xs={24}>
                <BroadcastMetrics height={height} />
              </Col>
            </Row>
          </div>
        </Space>
      </Suspense>
    </div>
  );
}

export default Overview;
