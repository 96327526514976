/** Vendors */
import axios from "axios";

/** Types */
import type {
  IMember,
  IMemberPayload,
  IMemberResponse,
  ISendVerifyEmail,
  ISendVerifyPasscode,
} from "../types";

/* Member API Endpoints */
export async function getMemberApi(
  params: Partial<IMember>
): Promise<IMemberResponse> {
  return await axios.get<IMemberPayload, IMemberResponse>("/member", {
    params,
  });
}

export async function updateMemberApi(
  props: IMemberPayload
): Promise<IMemberResponse> {
  return await axios.post<IMemberPayload, IMemberResponse>("/member", props);
}

/** Verify Account */
export async function sendVerifyEmail<T>(props: ISendVerifyEmail): Promise<T> {
  return axios.post<ISendVerifyEmail, T>("/member/verify/email", props);
}

export async function sendVerifyPasscode<T>(
  props: ISendVerifyPasscode
): Promise<T> {
  return axios.post<ISendVerifyPasscode, T>("/member/verify/passcode", props);
}

const member = {
  get: getMemberApi,
  update: updateMemberApi,
};

const verify = {
  email: sendVerifyEmail,
  passcode: sendVerifyPasscode,
};

export { member, verify };
