/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Types */
import type { IChannel } from "../../types";
import type { AnyAction, IRootState } from "../configureStore";

const channel = createReducer(initialState.channel, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_CHANNEL_SUCCESS),
      (state: IRootState["channel"], action: AnyAction) => {
        return redux.addResources<IChannel>(state, action, "channel");
      }
    )
    .addCase(
      createAction(types.DELETE_CHANNEL_SUCCESS),
      (state: IRootState["channel"], action: AnyAction) => {
        return redux.removeMatchCase<IChannel>(state, action, "channel");
      }
    )
    .addCase(
      createAction(types.GET_CHANNEL_SUCCESS),
      (state: IRootState["channel"], action: AnyAction) => ({
        ...state,
        details: action.channel,
      })
    )
    .addCase(
      createAction(types.SEARCH_CHANNELS_SUCCESS),
      (state: IRootState["channel"], action: AnyAction) => {
        return redux.joinOrOverwrite<IChannel>(state, action);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_CHANNEL_SUCCESS),
      (state: IRootState["channel"], action: AnyAction) => ({
        ...state,
        details: action.channel,
      })
    )
    .addCase(
      createAction(types.TOGGLE_CHANNEL_MODAL),
      (state: IRootState["channel"], action: AnyAction) => ({
        ...state,
        details: action.details || state.details,
      })
    )
    .addCase(
      createAction(types.TOGGLE_CHANNEL_SIDEBAR),
      (state: IRootState["channel"], action: AnyAction) => ({
        ...state,
        details: action.details,
      })
    )
    .addCase(
      createAction(types.UPDATE_CHANNEL_SUCCESS),
      (state: IRootState["channel"], action: AnyAction) => {
        return redux.updateMatchCase<IChannel>(state, action, "channel");
      }
    )
    .addDefaultCase((state) => state);
});

export default channel;
