/** Vendors */
import { Card, Flex, Typography } from "antd";

/** Redux */
import toggleAction from "@redux/actions/toggle";

/** Custom Hooks */
import { useAppDispatch } from "@hooks/useRedux";

/** Custom Methods */
import { liveATCEndpoints } from "@dist/js/support";

/** Enums */
import { ResourceId, ToggleResource } from "types";

function Examples() {
  const dispatch = useAppDispatch();

  const actions = {
    onSelect: (endpoint) => () => {
      const params = {
        details: {
          ...endpoint,
          resource_id: ResourceId.Recording,
          resource_name: "",
        },
        resource: ToggleResource.TranscribeModal,
      };

      dispatch(toggleAction(params));
    },
  };

  return (
    <div className="overflow">
      <Flex className="overflow-y p-2" flex={1} gap={12}>
        {liveATCEndpoints.map((endpoint) => (
          <Card className="flex-1 pointer" onClick={actions.onSelect(endpoint)}>
            <Typography.Title className="m-0" level={4}>
              {endpoint.name}
            </Typography.Title>
            <Typography.Text className="m-0">
              {endpoint.description}
            </Typography.Text>
          </Card>
        ))}
      </Flex>
    </div>
  );
}

export default Examples;
