import axios from "axios";
import store from "../../redux/configureStore";

axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(
  (res) => res.data,
  function (error) {
    if (!error.response) return error;
    if (error.response.status === 401) {
      console.trace(error);
      store.dispatch({
        auth: { refresh: true },
        type: "AUTH_STATUS_CHANGE",
      });
    }
    return Promise.reject({
      description: error.response.data,
      status: error.response.status,
    });
  }
);
