import { useEffect } from "react";

/** Redux */
import { searchContactsAction } from "@redux/actions/contact";
import toggleAction from "@redux/actions/toggle";
import { updateSearchAction } from "@redux/actions/search";

/** Custom Hooks */
import useDelay from "@hooks/useDelay";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux";

/** Custom CSS */
import "./search.css";

/** Enums */
import { ToggleResource } from "types";

/** Types */
import type { IMember } from "types";

function SearchBar() {
  const delay = useDelay();
  const dispatch = useAppDispatch();

  const { contacts, search } = useAppSelector((state) => ({
    contacts: state.contact.list,
    search: state.search,
  }));

  /** Step 1. Monitor input text, no text no results. */
  useEffect(() => {
    delay(() => {
      dispatch(searchContactsAction(search));
    }, 200);
  }, [search.text]);

  /** Step 2. Monitor changes in text input */
  const actions = {
    /** Monitor Enter Key */
    monitorEnterKey: (event: any) => {
      event.preventDefault();
      if (event.keyCode === 13 && contacts.length) {
        actions.toggleDetails(contacts[0]);
      }
    },
    search: (text: string) => {
      dispatch(updateSearchAction({ is_top_level: true, text }));
    },
    toggleDetails: (details: IMember) => {
      dispatch(
        toggleAction({ details, resource: ToggleResource.ContactSidebar })
      );
    },
  };

  return null;

  // return (
  //   <Input.Search
  //     autoComplete="off"
  //     className="navbar-input"
  //     enterButton="Search"
  //     onSearch={(text) => actions.search(text)}
  //     onKeyUp={actions.monitorEnterKey}
  //     pattern="[a-zA-Z0-9/s.@]+"
  //     placeholder={search.placeholder || "Search for contacts..."}
  //     size="large"
  //     value={(search.is_top_level && search.text) || ""}
  //   />
  // );
}

export default SearchBar;
