import { useEffect, useState } from "react";

/** Redux */
import * as types from "../redux/actions/types";
import store from "@redux/configureStore";

/** Enums */
import { ResourceId, Tool } from "../types";

/** Types */
export interface IUseAccess {
  access_key: string;
  resource_id: ResourceId;
  resource_name: string;
  tool: Tool;
}

//Use to show / hide components based on permission level required
function useAccess({
  access_key,
  resource_id,
  resource_name = "",
  tool,
}: IUseAccess): boolean {
  const { access } = store.getState();
  const [details, setDetails] = useState({
    access: {},
    original_key: `${access_key}.${resource_name ? resource_name : ""}`,
    resource_name,
    show: false,
  });

  /** Step 1. Send access request. */
  useEffect(() => {
    setDetails({
      ...details,
      original_key: `${access_key}.${resource_name}`,
    });

    store.dispatch({
      payload: {
        access: {
          access_key,
          resource_id,
          resource_name,
          tool,
        },
        action: "access",
        method: "GET",
        path: "access",
        type: "GET_ACCESS_SUCCESS",
      },
      type: types.SOCKET_SEND_MESSAGE,
    });
  }, [details.resource_name]);

  //Step 2. Monitor changes in access as changes are made
  useEffect(() => {
    setDetails({ ...details, show: access[details.original_key] });
  }, [access[details.original_key]]);

  return details.show;
}

export default useAccess;
