/** @link https://docs.amplify.aws/lib/utilities/cache/q/platform/js/ */
import { Cache } from "aws-amplify/utils";
import _ from "lodash";

/** Enums */
import { AsyncStorageKey } from "../types";

/** Types */
import type { IAnyObject } from "../types";

type IKeyOnly = { key: AsyncStorageKey };
type IKeyValuePair = { key: AsyncStorageKey; value: IAnyObject };

const useStorage = () => {
  const clear = async () => {
    try {
      await Cache.clear();
      return true;
    } catch (e) {
      return false;
    }
  };

  const getAllKeys = async (): Promise<string[]> => {
    let keys = [] as string[];
    try {
      keys = await Cache.getAllKeys();
      return keys.map((k) => k.slice(1));
    } catch (e) {
      return keys;
    }
  };

  const getItem = async ({ key }: IKeyOnly) => {
    const value = await Cache.getItem(`@${key}`);

    return value !== null
      ? value.includes("{")
        ? JSON.parse(value)
        : value
      : false;
  };

  const mergeItem = async ({ key, value }: IKeyValuePair) => {
    try {
      /** Step 1. Ensure key exists first. */
      const previous = await getItem({ key }).then(async (value) => {
        if (value) return value;
        await setItem({ key, value });
        return {};
      });

      /** Step 2. Merge Items. */
      await setItem({ key, value: _.merge(previous, value) });
    } catch (error) {
      console.log({ error });
    }
  };

  const removeItem = async ({ key }: IKeyOnly) => {
    try {
      await Cache.removeItem(`@${key}`);
    } catch (error) {
      console.log({ error });
    }
  };

  const setItem = async ({ key, value }: IKeyValuePair) => {
    try {
      await Cache.setItem(`@${key}`, JSON.stringify(value));
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    clear,
    getAllKeys,
    getItem,
    mergeItem,
    removeItem,
    setItem,
  };
};

export default useStorage;
