/** Vendors */
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Redux */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

/** Types */
import type { AnyAction, IRootState } from "../configureStore";
import type { IMetadata, IStandardList } from "../../types";

//CRUD operations for the respective metadata object.
const crudHandler = (
  state: IRootState["metadata"],
  action: AnyAction,
  method: "create" | "delete" | "get" | "reset" | "update"
) => {
  const resource_id = Object.keys(action).filter((resource_id) => {
    return !["cid", "connectionId", "message", "requestId", "type"].includes(
      resource_id
    );
  })[0];

  if (method === "get") {
    return { ...state, details: action[resource_id] };
  }

  const executionMethod =
    method === "delete"
      ? redux.removeMatchCase
      : method === "create"
        ? redux.addResources
        : method === "reset"
          ? redux.resetResources
          : method === "update"
            ? redux.updateMatchCase
            : redux.addResources;

  type IMType = IMetadata<typeof ResourceId>;
  const params = state[
    resource_id as keyof typeof state
  ] as IStandardList<IMType>;

  return {
    ...state,
    [resource_id as keyof typeof state]: executionMethod(
      params,
      action,
      resource_id
    ),
  };
};

const appliedFiltered = (
  state: IRootState["metadata"],
  action: AnyAction,
  resource_id: ResourceId
) => {
  type IMType = IMetadata<typeof resource_id>;
  const params = state[
    resource_id as keyof typeof state
  ] as IStandardList<IMType>;
  return {
    ...state,
    [resource_id as keyof typeof state]: redux.joinOrOverwrite<IMType>(
      params,
      action
    ),
  };
};

const metadata = createReducer(initialState.metadata, (builder) => {
  builder
    .addCase(
      createAction(types.CREATE_METADATA_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return crudHandler(state, action, "create");
      }
    )
    .addCase(
      createAction(types.DELETE_METADATA_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return crudHandler(state, action, "delete");
      }
    )
    .addCase(
      createAction(types.GET_METADATA_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return crudHandler(state, action, "get");
      }
    )
    .addCase(
      createAction(types.RESET_METADATA_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return crudHandler(state, action, "reset");
      }
    )
    .addCase(
      createAction(types.SEARCH_BASES_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Base);
      }
    )
    .addCase(
      createAction(types.SEARCH_BUILDINGS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Building);
      }
    )
    .addCase(
      createAction(types.SEARCH_BILLETS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Billet);
      }
    )
    .addCase(
      createAction(types.SEARCH_DEFINITIONS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Definition);
      }
    )
    .addCase(
      createAction(types.SEARCH_EXERCISES_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Exercise);
      }
    )
    .addCase(
      createAction(types.SEARCH_MOSS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.MOS);
      }
    )
    .addCase(
      createAction(types.SEARCH_ORGANIZATIONS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Organization);
      }
    )
    .addCase(
      createAction(types.SEARCH_PRODUCTS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Product);
      }
    )
    .addCase(
      createAction(types.SEARCH_RANKS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Rank);
      }
    )
    .addCase(
      createAction(types.SEARCH_ROOMS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Room);
      }
    )
    .addCase(
      createAction(types.SEARCH_SECTIONS_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return appliedFiltered(state, action, ResourceId.Section);
      }
    )
    .addCase(
      createAction(types.SET_ACTIVE_METADATA_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => ({
        ...state,
        details: action.details,
      })
    )
    .addCase(
      createAction(types.TOGGLE_METADATA_MODAL),
      (state: IRootState["metadata"], action: AnyAction) => ({
        ...state,
        details: action.details,
      })
    )
    .addCase(
      createAction(types.UPDATE_METADATA_SUCCESS),
      (state: IRootState["metadata"], action: AnyAction) => {
        return crudHandler(state, action, "update");
      }
    )
    .addDefaultCase((state) => state);
});

export default metadata;
